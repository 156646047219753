import { FC } from 'react'
import { Card, SpacedBox, Text, Field } from "../../../components"

interface BridgeOrganizationCardProps {
  id: string
  name: string
  onClick?: () => void
}

const BridgeOrganizationCard: FC<BridgeOrganizationCardProps> = ({ id, name, onClick }) =>
  <Card onClick={onClick}>
    <SpacedBox align="spread">
      <Text>{name}</Text>
      <Field title="ID:">{id}</Field>
    </SpacedBox>
  </Card>

export default BridgeOrganizationCard
