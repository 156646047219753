import { FC } from "react"
import { useObservable, Text } from "../../components"
import { useCore } from "../../core"
import { TenantMember, UserProfile } from "./types"

interface UserNameProps {
  id: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

const UserName: FC<UserNameProps> = ({ id, size = "sm" }) => {
  const { core } = useCore()

  // Users referenced from other objects are membership records, user profile has to be de-references
  const tenantMember = useObservable(core.getDocument<TenantMember>(id))
  const user = useObservable(core.getDocument<UserProfile>(tenantMember && tenantMember.body && tenantMember.body.user))
  if (!user) {
    return null
  }

  return user.body.name
    ? <Text size={size}>{user.body.name} ({user.body.email})</Text>
    : <Text size={size}>{user.body.email}</Text>
}

export default UserName
