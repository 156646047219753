import styled from 'styled-components'

interface HeaderProps {
}

export const Header = styled.div<HeaderProps>`
  height: 4rem;
  width: 100%;
  display: flex;
  padding: 1rem;
  background-color: ${({ theme }) => theme.background.main};
  box-shadow: 1px 2px 3px rgba(0,0,0,0.1);
`
