import { FC } from "react"
import { Button, useTray, useObservable, Avatar } from "../../components"
import { useCore } from "../../core"
import MyProfile from "./my-profile"

const MyAvatar: FC<{}> = () => {
  const { isAuthenticated, login, core } = useCore()
  const { open: openTray } = useTray()

  const session = useObservable(core.session)
  if (!session) {
    return <Avatar size="m" />
  }

  const viewMyProfile = () => openTray(
    <MyProfile onBack={viewMyProfile} />
  )

  return (isAuthenticated
    ? <Avatar url={session.accountProfile.avatar} size="m" onClick={viewMyProfile} />
    : <Button onClick={login} color="secondary">Log in</Button>
  )
}

export default MyAvatar
