import { FC } from 'react'
import styled from 'styled-components'
import { sortChildren } from '../../utils'
import { Header } from "./header"
import { Footer } from "./footer"
import Actions from "./actions"

const Container = styled.div`
  position: relative;
  transition: box-shadow .3s;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border["main"]};
  width: 100%;
  margin: 0;
  cursor:  ${({ onClick }) => onClick ? "pointer" : "default"};

  &:hover ${Actions} {
    display: flex;
    flex-direction: row;
  }
`

const CardBody = styled.div`
  padding: 0.5rem 1rem;
`

interface CardProps {
  onClick?: () => void
}

type CardLayoutType = FC<CardProps> & {
  Header: FC
  Footer: FC
  Actions: FC
}

const Card: CardLayoutType = ({ children, onClick = () => { } }) => {
  const [header, footer, actions, ...body] = sortChildren([Header, Footer, Actions], children)

  return (
    <Container onClick={onClick}>
      {header}
      <CardBody>{body}</CardBody>
      {footer}
      <Actions>{actions}</Actions>
    </Container>
  )
}

Card.Header = Header
Card.Footer = Footer
Card.Actions = Actions

export default Card
