import { FC } from 'react'
import styled from 'styled-components'
import { Text } from "./index"

const colors = {
  grey: "#434343",
  green: "#438343",
  orange: "#AA7700",
  red: "#A34343"
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Indicator = styled.div`
  background-color: ${({ color }) => color};
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
`

interface StatusIndicatorProps {
  status?: "grey"|"green"|"orange"|"red"
  text?: string
}

const StatusIndicator: FC<StatusIndicatorProps> = ({ status, text = "" }) =>
  <Container>
    <Indicator color={colors[status || "grey"]} />
    <Text>{text}</Text>
  </Container>

export default StatusIndicator
