import styled from 'styled-components'

interface DividerProps {
  size?: "sm"|"m"|"lg"
}

export const Divider = styled.div<DividerProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: end;
  padding: 0.5rem 0;
  border-bottom: ${(props) => props.theme.border["main"]};
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
  color: ${(props => props.theme.textColor.main)};
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
`
