import { useState } from 'react'
import { useForm } from "../form-context"
import DatePicker  from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

const DateSelector = ({ field }) => {
  const { setField, getField } = useForm()
  const currentValue = getField(field) || new Date()
  const [value, setValue] = useState(new Date(currentValue))

  const onChange = (date) => {
    setValue(date)
    setField(field, date)
  }

  return (
    <DatePicker
      selected={value}
      isClearable
      onChange={onChange}
      placeholderText="YYYY-MM-DD"
      dateFormat="yyyy-MM-dd"
    />
  )
}

export default DateSelector
