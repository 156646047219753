import { FC } from "react"
import styled from 'styled-components'
import Icon from "../../icon"

const Container = styled.div`
  height: 100%;
`

interface RemoveItemIconProps {
  onClick: () => void
}

const RemoveItemIcon: FC<RemoveItemIconProps> = ({ onClick }) =>
  <Container>
    <Icon.XCircle onClick={onClick} size="24px" color="#944A00" />
  </Container>

export default RemoveItemIcon