import { FC } from 'react'
import styled from 'styled-components'
import Icon from '../icon'

interface ItemProps {
  onClick?: () => void
  current?: boolean
  icon?: string
  short?: boolean
  title: string
}

interface ContainerProps {
  current?: boolean
  onClick?: (event: any) => void
}

const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.textColor.inverted};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  padding: 1rem;
  border-left: 2px solid ${({ theme, current }) => current ? theme.background.main : "transparent" };
  margin: 0;

  &:hover {
    background-color: #00254B;
  }
`

const TitleText = styled.div<ContainerProps>`
  font-size: ${({ theme, current }) => current ? theme.fontSizes.m : theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  padding: 0 0.5rem;
  margin: auto 0;
`

const MenuItem: FC<ItemProps> = ({ icon, title, short = false, current = false, onClick = () => {} }) => {
  const IconComponent = icon ? Icon[icon] : Icon.Square

  return (
    <Container onClick={onClick ? (event) => { event.stopPropagation(); onClick() } : undefined} current={current}>
      <IconComponent size="20" />
      {!short && <TitleText>{title}</TitleText>}
    </Container>
  )
}

export default MenuItem
