import { BrowserRouter } from 'react-router-dom'
import { CoreProvider, LocalAuthClient, Auth0ClientAdapter } from './core'
import { ApplicationContainer } from "./components"
import { MainPage } from './features'

declare global {
  interface Window { CONFIG: any }
}

if (!window.CONFIG) {
  window.CONFIG = {}
}

const baseUrl = process.env.REACT_APP_BACKEND || window.CONFIG.backend || ''

const authContext = process.env.REACT_APP_AUTH_PROVIDER || window.CONFIG.authProvider || "local"
const authClient = authContext === 'local'
  ? new LocalAuthClient()
  : new Auth0ClientAdapter({
      domain: process.env.REACT_APP_AUTH_DOMAIN || window.CONFIG.authDomain || "",
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID || window.CONFIG.authClientId || "",
      redirectUri: window.location.origin
    })

const App = () =>
  <BrowserRouter>
    <ApplicationContainer>
      <CoreProvider authClient={authClient} baseUrl={baseUrl}>
        <MainPage />
      </CoreProvider>
    </ApplicationContainer>
  </BrowserRouter>

export default App
