import { FC } from 'react'
import { SpacedBox, Text, Section, ActionButton } from "../../components"

interface ConfirmationModalProps {
  text: string
  onConfirm: () => void|Promise<void>
  onDone: () => void
  onCancel: () => void
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ text, onConfirm, onDone, onCancel }) =>
  <Section>
    <SpacedBox direction="column">
      <Text>{text}</Text>
    </SpacedBox>

    <Section.Footer>
      <SpacedBox align="spread">
        <ActionButton onClick={onConfirm} title="Confirm" icon="Check" onComplete={onDone} />
        <ActionButton onClick={onCancel} title="Cancel" icon="X" color="secondary" />
      </SpacedBox>
    </Section.Footer>
  </Section>
