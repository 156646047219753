import { FC } from 'react'
import Image from "./image"

const defaultAvatar = 'https://www.gravatar.com/avatar/?d=mp'

interface AvatarProps {
  url?: string
  size?: 'xsm'|'sm'|"m"|'lg'|'xlg'
  shape?: 'circle'|'square'
  onClick?: () => void
}

const Avatar: FC<AvatarProps> = ({ url, onClick, size = 'xsm', shape = 'circle' }) =>
  <Image
    src={url || defaultAvatar}
    shape={shape}
    size={size}
    onClick={onClick}
  />

export default Avatar
