import { FC } from "react"
import { ActionButton, Section, Title, Form, Module } from "../../../components"
import { useCore } from "../../../core"

interface InvitationFormProps {
  tenantId: string
  onSuccess: () => void
}

const InvitationForm: FC<InvitationFormProps> = ({ tenantId, onSuccess }) => {
  const { core } = useCore()

  const invite = async ({ email, role }) => {
    const reply = await core.command("navarik.id.user.getByEmail", { email })
    let user = reply.result
    if (!user) {
      const reply = await core.command("navarik.id.user.createByEmail", { email })
      user = reply.result
    }

    await core.command("navarik.id.tenant.membership.invite", {
      tenant: tenantId,
      user: user.id,
      role
    })

    onSuccess()
  }

  const isUnique = async (email: string) => {
    const reply = await core.command("navarik.id.user.getByEmail", { email })
    let user = reply.result
    if (!user) {
      return true
    }
    const { result: { total } } = await core.command("navarik.id.tenant.membership.list", { tenant: tenantId, user: user.id })
    return !total
  }

  const roles = {
    admin: "Administrator",
    user: "User"
  }

  return (
    <Section>
      <Section.Header>
        <Title>Add User</Title>
      </Section.Header>

      <Form onSubmit={invite} data={{ role: "user" }}>
        <Module>
          <Form.Field>
            <Form.Label>Role</Form.Label>
            <Form.Select field="role" options={roles} />
            <Form.Validators.Required field="role">This field cannot be blank</Form.Validators.Required>
          </Form.Field>

          <Form.Field>
            <Form.Label>Email</Form.Label>
            <Form.Input type="email" placeholder="email" field="email" />
            <Form.Validators.Required field="email">This field cannot be blank</Form.Validators.Required>
            <Form.Validators.Constraint fields={["email"]} constraint={isUnique}>User is already a member of the organization</Form.Validators.Constraint>
          </Form.Field>

          <ActionButton type="submit" title="Add User" icon="Mail" />
        </Module>
      </Form>
    </Section>
  )
}

export default InvitationForm
