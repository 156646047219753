import { createContext, useContext } from 'react'

interface FormInterface {
  getField<T = any>(name: string): T
  setField<T = any>(name: string, value: T): void
  registerValidator(validator: () => boolean | Promise<boolean>): void
  submitForm(): void
}

export const FormContext = createContext<Partial<FormInterface>>({})

export const useForm = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error(`useForm must be used within a Form`)
  }

  return context as FormInterface
}
