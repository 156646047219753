import { FC } from "react"
import styled from 'styled-components'
import Icon from "../../icon"

interface ActionIconProps {
  type: string
  disabled?: boolean
  onClick: () => void
}

const Container = styled.div`
  cursor: pointer;
`

const ActionIcon: FC<ActionIconProps> = ({ type, onClick, disabled = false }) => {
  const IconComponent = Icon[type]
  const color = disabled ? "#727272" : "#004A97"
  const handler = disabled ? () => {} : onClick

  return (
    <Container onClick={handler}>
      <IconComponent color={color} size="20px" />
    </Container>
  )
}

export default ActionIcon