import { FC, useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react"
import styled from 'styled-components'
import { usePrevious } from "../../hooks"
import EmojiSuggestionList from "./emoji-suggestion-list"
import EmojiPicker from "./emoji-picker"
import ActionCluster from "./action-cluster"
import ActionIcon from "./action-icon"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #f8f9fa;
  width: 100%;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 5px;
`

const Textarea = styled.textarea`
  overflow: hidden;
  resize: none;
  border: none;
  outline: none;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  max-height: 20vh;
  height: 44px;
  padding-right: 8em;
  border-radius: 10px;
`

const CharacterNumber = styled.span`
  background-color: #dc3545;
  color: white;
  height: fit-content;
  align-self: flex-end;
  position: absolute;
  right: 0px;
  bottom: 2.6rem;
  margin-right: 28px;
`

interface CommandPromptProps {
  onSubmit: (text: string) => void
  onUploadAction: () => void
  onPrevious: () => void
  defaultValue?: string
  ref: any
}

interface AutosuggestionState {
  active: boolean
  position: number
}

const CommandPrompt: FC<CommandPromptProps> = forwardRef(
  ({ onSubmit, onPrevious, defaultValue = "", onUploadAction }, ref) => {
    const [text, setText] = useState("")
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)

    const [suggesting, setSuggesting] = useState<AutosuggestionState>({ active: false, position: 0 })

    const [cursorPosition, setCursorPosition] = useState(null)
    const [isTextForEdit, setIsTextForEdit] = useState(false)
    const inputRef = useRef<any>(null)

    const emptyTextAreaHeight = 44
    const characterLimit = 2000

    const setTextareaHeight = (height) => {
      inputRef.current.style.height = height + "px"
    }

    if (usePrevious(defaultValue) !== defaultValue && defaultValue !== text) {
      setText(defaultValue)
      setIsTextForEdit(true)
      if (suggesting.active) {
        setSuggesting({ active: false, position: 0 })
      }
    }

    useEffect(() => {
      const cursorStart = inputRef.current.selectionStart
      if (isTextForEdit) {
        inputRef.current.setSelectionRange(text.length, text.length)
        setIsTextForEdit(false)
        setCursorPosition(inputRef.current.selectionStart)
      } else if (cursorPosition && cursorStart !== cursorPosition) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
      }
      setFocus()
    }, [text, isTextForEdit, cursorPosition])

    const handleSubmit = () => {
      if (text.length <= characterLimit) {
        onSubmit(text)
        setText("")
        setTextareaHeight(emptyTextAreaHeight)
      }
    }

    const setFocus = () => {
      if (inputRef && inputRef.current && !isEmojiPickerOpen ) {
        inputRef.current.focus()
        setTextareaHeight(inputRef.current.scrollHeight)
      }
    }

    const setBlur = () => {
      if (inputRef && inputRef.current) {
        setTextareaHeight(emptyTextAreaHeight)
        inputRef.current.blur()
      }
    }

    useImperativeHandle(ref, () => ({
      focus: setFocus,
      blur: setBlur,
    }))

    const addEmojiFromPicker = (emoji) => {
      setText(text + emoji.native)
      setIsEmojiPickerOpen(false)
      setFocus()
    }

    const [suggestionTarget] = text.slice(suggesting.position + 1).split(" ")

    const startSuggesting = (position: number) => {
      setSuggesting({ active: true, position })
    }

    const onSuggetionListSelect = (emoji) => {
      const beforeEmoji = text.slice(0, suggesting.position)
      const afterEmoji = text.slice(suggesting.position + suggestionTarget.length + 1)

      const newText = beforeEmoji + emoji.native + afterEmoji

      setText(newText)
      setFocus()
      setSuggesting({ active: false, position: 0 })
    }

    const onChangeHandler = (event) => {
      setCursorPosition(event.target.selectionStart)
      setText(event.target.value)

      // set height of textarea
      if (inputRef && inputRef.current) {
        // setTextareaHeight(0)
        setTextareaHeight(inputRef.current.scrollHeight)
      }
    }

    const onKeyDownHandler = (event) => {
      if (event.key === "ArrowUp") {
        onPrevious()
      } else if (event.key === ":") {
        startSuggesting(event.target.selectionStart)
      } else if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault()
        handleSubmit()
      }
    }

    return (
      <>
        {suggesting.active &&
          <EmojiSuggestionList
            word={suggestionTarget}
            onPick={onSuggetionListSelect}
            onCancel={() => setSuggesting({ active: false, position: 0 })}
          />
        }

        {isEmojiPickerOpen && (
          <EmojiPicker onPick={addEmojiFromPicker} onClose={() => setIsEmojiPickerOpen(false)} />
        )}

        <Wrapper>
          <Textarea
            ref={inputRef}
            placeholder="Type your message here..."
            value={text}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHandler}
            autoFocus
          />
          {text.length > characterLimit && (
            <CharacterNumber>{characterLimit - text.length}</CharacterNumber>
          )}

          <ActionCluster>
            <ActionIcon type="Upload" onClick={onUploadAction} />
            <ActionIcon type="Smile" onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)} />
            <ActionIcon type="Send" onClick={handleSubmit} disabled={text.length > characterLimit} />
          </ActionCluster>
        </Wrapper>
      </>
    )
  }
)

export default CommandPrompt