import { FC } from 'react'
import { Section, useObservable, SpacedBox, ActionButton, useTray } from '../../components'
import { useCore } from '../../core'
import Tenant from '../tenant'
import CurrentPersonaCard from './current-persona-card'
import PersonaSwitch from './persona-switch'

interface MyProfileProps {
  onBack: () => void
}

const MyProfile: FC<MyProfileProps> = ({ onBack }) => {
  const { core, logout } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const session = useObservable(core.session)

  const switchToNewTenant = async ({ id }) => {
    const persona = core.session.currentValue().personas.find(x => x.tenant.id === id)
    persona && await core.switchPersona(persona.id)
    closeTray()
  }

  const createNewTenant = () => openTray(
    <Tenant.Form onSuccess={switchToNewTenant} onCancel={onBack} />,
    { size: "small" },
    onBack
  )

  return (
    <Section>
      <Section.Header>
        <CurrentPersonaCard onBack={onBack} />
      </Section.Header>

      {session.personas.length > 1 && <PersonaSwitch />}

      <Section.Footer>
        <SpacedBox align="spread">
          <ActionButton onClick={createNewTenant} color="primary" icon="Plus" title="Add Organization" />
          <ActionButton onClick={logout} color="danger" icon="LogOut" title="Log Out" />
        </SpacedBox>
      </Section.Footer>
    </Section>
  )
}

export default MyProfile
