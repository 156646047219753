import styled from 'styled-components'

interface LabelProps {
  position?: "left"|"center"|"right"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

const Label = styled.span<LabelProps>`
  color: ${(props => props.theme.textColor.light)};
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  align-self: ${({ theme, position = "left" }) => theme.positions[position]};
  margin-bottom: 0;
  word-break: break-all;
`

export default Label
