import { FC } from "react"
import styled from 'styled-components'
import { Title, Text, Image } from "../index"
import { Module } from "../boxes/module"
import FileIcon from "./thumbnail"

const Container = styled(Module)`
  height: 100%;
`

const Header = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

const ZoomImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width:100%;
  overflow: none;

  &:hover {
    cursor: zoom-in;
  }
`

interface FilePreviewProps {
  fileName: string
  format: string
  src: string
}

const FilePreview: FC<FilePreviewProps> = ({ fileName, format, src }) =>
  <Container>
    <Header>
      <Title size="sm">{fileName}</Title>
      <Text color="light">Click the image below to download</Text>
    </Header>

    <ZoomImageContainer>
      <a href={src} download>
      {format.includes("image") && src
        ? <Image src={src} alt={src} />
        : <FileIcon format={format} size="lg" />
      }
      </a>
    </ZoomImageContainer>
  </Container>

export default FilePreview
