import { useEffect, useState } from "react"
import { Observable } from "@navarik/types"

export function useObservable<T>(value: Observable<T>): T {
  const [current, update] = useState<T>(value.currentValue())

  useEffect(() => {
    const observerId = value.observe(update)

    return () => {
      value.unobserve(observerId)
    }
  }, [value, update])

  return current
}