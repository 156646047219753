import { FC } from 'react'
import { SpacedBox, List, useObservableCollection, useTray, Card, Module, Toolbar, ActionButton, Text, ActionableContainer, Icon, useModal } from "../../../components"
import { useCore } from '../../../core'
import { TenantMembership } from '../types'
import { ConfirmationModal } from '../../../shared'
import User from '../../user'
import MembershipStatus from './membership-status'
import InvitationForm from './invitation-form'

interface UserListProps {
  tenantId: string
}

const UserList: FC<UserListProps> = ({ tenantId }) => {
  const { open: openTray, close: closeTray } = useTray()
  const { open: openModal, close: closeModal } = useModal()
  const { core } = useCore()

  const memberships = useObservableCollection(core.query<TenantMembership>({
    type: "navarik.id.tenant.membership",
    "body.tenant": tenantId
  }, { limit: 1000, sort: "created_at:desc" }))


  const inviteUser = () => openTray(<InvitationForm tenantId={tenantId} onSuccess={closeTray} />)

  const getuserProfile = async (userId) => {
    const tenantMember = await core.command("navarik.id.tenant.membership.read", { id: userId })
    const user = await core.command("navarik.id.user.profile.get", { id: tenantMember.result.body.user })
    return user.result.body
  }

  const deleteUser = async (id) => {
    const userProfile = await getuserProfile(id)
    openModal(
      <ConfirmationModal
        text={`Are you sure you want to delete member ${userProfile.name === "Invited" ? '' : userProfile.name} (${userProfile.email}) ?`}
        onConfirm={() => core.command("navarik.id.tenant.membership.delete", { id })}
        onCancel={closeModal}
        onDone={closeModal}
      />,
      { title: "Confirm delete member." }
    )
  }


  return (
    <Module fullHeight>
      <Toolbar>
        <SpacedBox align="spread">
          <Text size="lg">Users</Text>
          <ActionButton onClick={inviteUser} icon="Plus" title="Add User" />
        </SpacedBox>
      </Toolbar>

      <List>
        {memberships.map((membership) =>
          <ActionableContainer key={`membership_list_item_${membership.id}`}>
            <Card key={`tenant_user_${membership.id}`}>
              <SpacedBox align="spread">
                <User.Name id={membership.id} />
                <MembershipStatus status={membership.body.status} />
              </SpacedBox>
            </Card>
            <ActionableContainer.Actions>
              <ActionableContainer.Action>
                <Icon.X onClick={() => deleteUser(membership.id)} />
              </ActionableContainer.Action>
            </ActionableContainer.Actions>
          </ActionableContainer>
        )}
      </List>
    </Module>
  )
}

export default UserList
