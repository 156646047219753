import { FC } from 'react'
import { Text, useObservable, Spinner, Size } from "../../components"
import { useCore } from '../../core'

interface ReferenceNameProps {
  id: string
  nameField?: string
  size?: Size
}

export const ReferenceName: FC<ReferenceNameProps> = ({ id, nameField = "name", size = "sm" }) => {
  const { core } = useCore()

  const reference = useObservable(core.getDocument(id))
  if (!reference) {
    return <Spinner />
  }

  const name = reference.body[nameField]

  return (
    <Text size={size}>{name}</Text>
  )
}
