import { FC, Children } from "react"
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 3;
`

const Item = styled.div`
  padding: 0.25rem;
`

const ActionCluster: FC = ({ children }) =>
  <Container>
    {Children.toArray(children).map((child, i) =>
      <Item key={`item_${i}`}>
        {child}
      </Item>
    )}
  </Container>

export default ActionCluster