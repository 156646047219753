import { FC, useState } from 'react'
import { Switch, Redirect, Route, useHistory } from 'react-router-dom'
import { AppLayout, SpacedBox, Panel, useObservable } from "../components"
import { useCore } from '../core'
import { MainMenu } from '../shared/main-menu/main-menu'
import CurrentUser from './current-user'
import Tenant from "./tenant"
import ServiceAccount from './service-account'
import Workspace from './workspace'

type UiState = "full"|"short"

export const MainPage: FC<{}> = () => {
  const [state, setState] = useState<UiState>("full")
  const flipState = () => setState(state ==="full" ? "short" : "full")

  const { core } = useCore()

  const session = useObservable(core.session)
  const isNewUser = !session.personas.length

  const history = useHistory()

  const navigateTo= url => history.push(url)

  return (
    <AppLayout short={state === "short"}>
      <AppLayout.Tray />

      <AppLayout.Header>
        <SpacedBox align="end">
          <CurrentUser.MyAvatar />
        </SpacedBox>
      </AppLayout.Header>

      <AppLayout.Sidebar>
        <MainMenu appName="Navarik ID" state={state} flipState={flipState} navigateTo={navigateTo} items={[
          { icon: "Home", title: "Organization", route: "/organization" },
          { icon: "User", title: "Users", route: "/users" },
          { icon: "Settings", title: "Service Accounts", route: "/service-accounts" },
          { icon: "Share2", title: "Workspaces", route: "/workspaces" },
        ]} />
      </AppLayout.Sidebar>

      {isNewUser
        ? <CurrentUser.NewUserPage />
        :
          <Panel>
            <Switch>
              <Redirect exact from="/" to="/organization" />

              <Route exact path="/organization">
                <Tenant.View id={session.tenant.id} />
              </Route>

              <Route exact path="/users">
                <Tenant.Members tenantId={session.tenant.id} />
              </Route>

              <Route exact path="/service-accounts">
                <ServiceAccount.List tenantId={session.tenant.id} onView={id => navigateTo(`/service-accounts/${id}`)} />
              </Route>

              <Route exact path="/service-accounts/:id" render={({ match: { params: { id } } }) =>
                <ServiceAccount.View tenantId={session.tenant.id} id={id} />
              } />

              <Route exact path="/workspaces">
                <Workspace.List onView={id => navigateTo(`/workspaces/${id}`)} />
              </Route>

              <Route exact path="/workspaces/:id" render={({ match: { params: { id } } }) =>
                <Workspace.View id={id} />
              } />
            </Switch>
          </Panel>
      }
    </AppLayout>
  )
}
