import { FC } from 'react'
import { Card, Text, Timestamp } from "../../../components"
import { ClientSecret } from '../types'

interface SecretCardProps {
  created_at: string
  body: ClientSecret
}

const SecretCard: FC<SecretCardProps> = ({ created_at, body }) =>
  <Card>
    <Text>{body.description}, generated <Timestamp>{created_at}</Timestamp></Text>
  </Card>

export default SecretCard
