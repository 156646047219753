import { FC } from 'react'
import styled from 'styled-components'
import { Text, Label } from './typography'

const Container = styled.div`
`

const LabelSpace = styled.span`
  padding-right: 1rem;
`

interface FieldProps {
  title: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

export const Field: FC<FieldProps> = ({ title, children, size = "sm" }) =>
  <Container>
    <LabelSpace>
      <Label size={size}>{title}</Label>
    </LabelSpace>

    <Text size={size}>{children}</Text>
  </Container>
