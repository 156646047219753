import { FC } from 'react'
import { useObservable, Label, Text, List, Divider, PaddedBox, useModal, useTray } from '../../components'
import { useCore } from '../../core'
import { ConfirmationModal } from '../../shared'

interface PersonaSwitchProps {
}

const PersonaSwitch: FC<PersonaSwitchProps> = () => {
  const { core, switchPersona } = useCore()
  const { open: openModal, close: closeModal } = useModal()
  const { close: closeTray } = useTray() // Hack

  const session = useObservable(core.session)

  const onDone = () => {
    closeModal()
    closeTray()
  }

  const confirmPersonaSwitch = async (id, tenantName) => openModal(
    <ConfirmationModal
      text={`Do you want to switch your current organization to ${tenantName}?`}
      onConfirm={() => switchPersona(id)}
      onCancel={onDone}
      onDone={onDone}
    />,
    { title: "Switch Organization" }
  )

  return (
    <>
      <Divider>
        <Label>Switch Organization</Label>
      </Divider>

      <List>
      {session.personas.map(({ id, tenant }) =>
        tenant.id !== session.tenant.id &&
        <PaddedBox key={`tenant_${tenant.id}`} onClick={() => confirmPersonaSwitch(id, tenant.name)}>
          <Text size="m">{tenant.name}</Text>
        </PaddedBox>
      )}
      </List>
    </>
  )
}

export default PersonaSwitch
