import { FC } from "react"
import styled from 'styled-components'
import { sortChildren } from "../../utils"
import RequiredValidator from "./validators/required"
import Label from "./label"

interface FieldProps {
  id?: string
  direction?: "column"|"row"
}

const Container = styled.div<{ direction: "column"|"row" }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  padding: 0.25rem 0;
`

const Control = styled.div`
  width: 100%;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
`

const Field: FC<FieldProps> = ({ direction = "column", children }) => {
  const [label, requiredValidator, ...rest] = sortChildren([Label, RequiredValidator], children)

  return (
    <Container direction={direction}>
      {label}
      <Control>
        {rest}
        {requiredValidator}
      </Control>
    </Container>
  )
}

export default Field
