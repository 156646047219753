import { useEffect, useState } from "react"
import { ObservableCollection } from "@navarik/core-client-sdk"

export function useObservableCollection<T>(value: ObservableCollection<T>): Array<T> {
  const [current, update] = useState<Array<T>>([])

  useEffect(() => {
    const observerId = value.observe(x => update(x.toArray()))

    const startingPoint = value.currentValue().toArray()
    if (JSON.stringify(startingPoint) !== JSON.stringify(current)) {
      update(startingPoint)
    }

    return () => {
      value.unobserve(observerId)
    }
  }, [value, update])

  return current
}