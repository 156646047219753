import { FC } from 'react'
import { useForm } from "../form-context"
import { Checkbox } from "../../controls"

interface FormCheckboxProps {
  field: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlight"|"inverted"|"danger"
  label?: string
}

const FormCheckbox: FC<FormCheckboxProps> = ({ field, size, color, label }) => {
  const { getField, setField } = useForm()

  return (
    <Checkbox
      size={size}
      color={color}
      label={label}
      value={getField(field)}
      onChange={(value) => setField(field, value)}
    />
  )
}

export default FormCheckbox
