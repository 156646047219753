import { FC } from 'react'
import { Form, SpacedBox, Section, Title, ActionButton, Toolbar, Text, Module } from "../../components"
import { useCore } from '../../core'
import ReferenceSelector from '../../shared/reference/reference-selector'
import { Workspace } from "./types"

interface WorkspaceFormProps {
  id?: string
  body?: Workspace
  onSuccess: () => any
  onCancel: () => any
}

const WorkspaceForm: FC<WorkspaceFormProps> = ({ id, onSuccess, onCancel, body }) => {
  const { core } = useCore()

  const isDifferent = (client, vendor) => {
    return !(client === vendor && client && vendor)
  }

  const save = async ({ description, parties }) => {
    const body = { description, parties }

    await id
      ? core.command("navarik.bridge.workspace.update", { id, description })
      : core.command("navarik.bridge.workspace.create", body)

    onSuccess()
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Section>
        <Section.Header>
          <Title>{id ? "Edit Workspace" : "New Workspace"}</Title>
        </Section.Header>

        <Module>
          <Form.Field id="description">
            <Form.Label>Description</Form.Label>
            <Form.Input autoFocus type="text" field="description" />
            <Form.Validators.Required field="description">This field cannot be blank</Form.Validators.Required>
          </Form.Field>

          {!id && // Workspace parties are immutable
            <Module>
              <Toolbar>
                <Text size="lg">Parties</Text>
              </Toolbar>

              <Form.Field id="client">
                <Form.Label>Client</Form.Label>
                <ReferenceSelector field="parties.client.id" nameField="name" entityType="navarik.bridge.organization" />
                <Form.Validators.Required field="parties.client.id">This field cannot be blank</Form.Validators.Required>
              </Form.Field>

              <Form.Field id="vendor">
                <Form.Label>Vendor</Form.Label>
                <ReferenceSelector field="parties.vendor.id" nameField="name" entityType="navarik.bridge.organization" />
                <Form.Validators.Required field="parties.vendor.id">This field cannot be blank</Form.Validators.Required>
              </Form.Field>

              <Form.Validators.Constraint fields={['parties.client.id', 'parties.vendor.id']} constraint={isDifferent}>Client and vendor cannot be the same</Form.Validators.Constraint>

            </Module>
          }
        </Module>

        <Section.Footer>
          <SpacedBox align="spread">
            <ActionButton type="submit" title="Save" icon="Save" />
            <ActionButton color="secondary" title="Cancel" icon="X" onClick={onCancel} />
          </SpacedBox>
        </Section.Footer>
      </Section>
    </Form>
  )
}

export default WorkspaceForm
