import { FC } from 'react'
import styled from 'styled-components'
import logo from "./logo.png"

interface AppSelectorProps {
  navigateTo: (path: string) => void
}

const LogoImage = styled.img`
  max-width: 2.5rem;
  max-height: 2.5rem;
  cursor: pointer;
`

const AppSelector: FC<AppSelectorProps> = ({ navigateTo }) =>
  <LogoImage src={logo} alt="logo" onClick={() => navigateTo("/")} />

export default AppSelector
