import { FC } from "react"
import styled from 'styled-components'
import Timestamp from "../timestamp"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const HorizontalLine = styled.hr`
  width: 96%;
  color: #FFFF00;
  margin-bottom: -13px;
`
const DateBubble = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9em;
  width: fit-content;
`

interface DateDividerProps {
  timestamp: string
}

const DateDivider: FC<DateDividerProps> = (props) =>
  <Wrapper>
    <HorizontalLine />
    <DateBubble>
      <Timestamp>{props.timestamp}</Timestamp>
    </DateBubble>
  </Wrapper>

export default DateDivider
