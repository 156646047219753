import { FC, useState } from 'react'
import styled from 'styled-components'
import { Divider } from '../divider'
import { Text } from "../typography"
import Icon from '../icon'

interface CollapsibleBlockProps {
  title: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const CollapsibleBlock: FC<CollapsibleBlockProps> = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <Container>
      <Divider onClick={() => setCollapsed(!collapsed)}>
        <Text>{title}</Text>
        {collapsed ? <Icon.ChevronRight size="15px" /> : <Icon.ChevronDown size="15px" />}
      </Divider>
      {!collapsed && children}
    </Container>
  )
}
