import { FC, PropsWithChildren } from "react"
import styled from 'styled-components'
import { sortChildren } from "../utils"

const ActionSpace = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  display: flex;
  width: fit-content;
  height: 1.75rem;
  background: #fff;

  border: 2px solid #d9d9d9;
  background-color: white;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  transition: box-shadow 0.3s;

  visibility: hidden;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  background: #fff;

  &:hover ${ActionSpace} {
    visibility: visible;
  }
`

const Action = styled.div`
  cursor: pointer;
`

const Actions: FC = ({ children }) => <>{children}</>

type ActionableContainerType = FC<PropsWithChildren<{}>> & {
  Actions: FC
  Action: FC
}

const ActionableContainer: ActionableContainerType = ({ children }) => {
  const [actions, ...rest] = sortChildren([Actions], children)

  return (
    <Container>
      {rest}
      {actions && <ActionSpace>{actions}</ActionSpace>}
    </Container>
  )
}

ActionableContainer.Actions = Actions
ActionableContainer.Action = Action

export default ActionableContainer
