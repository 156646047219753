import { FC, useState } from 'react'
import styled from "styled-components"
import { SpacedBox } from '../boxes'
import { Text } from "../typography"

interface CheckboxProps {
  onChange: (value: boolean) => void
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlight"|"inverted"|"danger"
  value?: boolean
  label?: string
}

const Checkmark = styled.input<{ checked }>`
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  cursor: pointer;
  background-color: ${({ checked, theme }) => checked ? theme.background["main"] : theme.background["inverted"]};
  background-clip: padding-box;
  border: ${({ checked, theme }) => checked ? `1px solid ${theme.background["main"]}` : theme.border["main"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

export const Checkbox: FC<CheckboxProps> = ({ onChange, size, color, label = "", value = false }) => {
  const [currentValue, setCurrentValue] = useState(value)

  return (
    <SpacedBox align="start">
      <Checkmark
        type="checkbox"
        checked={currentValue}
        onChange={(element) => {
          const newValue = element.currentTarget.checked
          setCurrentValue(newValue)
          onChange(newValue)
        }}
      />
      <Text size={size} color={color}>{label}</Text>
    </SpacedBox>
  )
}
