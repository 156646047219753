import React from 'react'
import styled from 'styled-components'
import objectPath from "object-path"
import { Dictionary } from '@navarik/types'
import Field from './field'
import Label from './label'
import Input from './controls/input'
import Feedback from "./feedback"
import Checkbox from "./controls/checkbox"
import Select from "./controls/select"
import DateSelector from "./controls/date-selector"
import ArrayField from './controls/array-field'
import { FormContext } from "./form-context"
import RequiredValidator from './validators/required'
import ConstraintValidator from './validators/constraint'

const FormContainer = styled.form<{ direction: "column"|"row"}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-self: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`

interface FormProps<T> {
  data?: object
  children: any
  direction?: "column"|"row"
  onSubmit: (fields: T) => void
  onChange?: (fields: T) => void
}

interface FormState<T> {
  fields: T
  validated: boolean
  isValid: boolean
}

export class Form<T extends object = Dictionary<any>> extends React.Component<FormProps<T>, FormState<T>> {
  static Field: typeof Field = Field
  static Label: typeof Label = Label
  static Input: typeof Input = Input
  static Feedback: typeof Feedback = Feedback
  static Checkbox: typeof Checkbox = Checkbox
  static Select: typeof Select = Select
  static ArrayField: typeof ArrayField = ArrayField
  static DateSelector: typeof DateSelector = DateSelector
  static Validators: {
    Required: typeof RequiredValidator
    Constraint: typeof ConstraintValidator
  } = {
      Required: RequiredValidator,
      Constraint: ConstraintValidator
    }

  private contextApi
  private validators: Array<() => boolean>

  constructor(props) {
    super(props)

    this.validators = []

    this.state = {
      fields: props.data || {},
      validated: false,
      isValid: false
    }

    this.contextApi = {
      getField: (name) => this.getField(name),
      setField: (name, value) => this.setField(name, value),
      submitForm: () => this.props.onSubmit(this.state.fields),
      registerValidator: (validator) => this.registerValidator(validator)
    }
  }

  getField(name: string) {
    return objectPath.get(this.state.fields, name)
  }

  setField<T>(name: string, value: T) {
    if (this.state.fields[name] === value) {
      return
    }

    const fields = { ...this.state.fields }
    objectPath.set(fields, name, value)

    this.setState({ fields, validated: false })

    if (this.props.onChange) {
      this.props.onChange({ ...this.state.fields })
    }
  }

  componentDidUpdate() {
    if (!this.state.validated) {
      this.validate()
    }
  }

  registerValidator(validator) {
    this.validators = [...this.validators, validator]
  }

  async validate() {
    const validationResults = await Promise.all(this.validators.map(validator => validator()))
    const isValid = !validationResults.filter(x => x === false).length

    if (isValid !== this.state.isValid || !this.state.validated) {
      this.setState({ isValid, validated: true })
    }

    return isValid
  }

  handleSubmit(event) {
    if (event.preventDefault) {
      event.preventDefault()
    } else {
      event.returnValue = false
    }

    this.validate()
      .then((res) => {
        if (res) {
          this.props.onSubmit(this.state.fields)
        }
      })
  }

  render() {
    return (
      <FormContext.Provider value={this.contextApi}>
        <FormContainer onSubmit={(event) => this.handleSubmit(event)} direction={this.props.direction || "column"}>
          {this.props.children}
        </FormContainer>
      </FormContext.Provider>
    )
  }
}

export default Form
