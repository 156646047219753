import { FC } from 'react'
import styled from 'styled-components'
import { sortChildren } from '../../utils'
import Item from './item'
import Section from './section'
import { Footer } from "./footer"
import { Header } from "./header"

type MenuType = FC & {
  Section: typeof Section
  Item: typeof Item
  Footer: typeof Footer
  Header: typeof Header
}

const Wrapper = styled.menu`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: spread;
  padding: 0;
  margin: 0;
`

const Main = styled.menu`
  height: 100%;
  padding: 1rem 0.5rem;
  margin: 0;
`

const Menu: MenuType = ({ children }) => {
  const [header, footer, ...main] = sortChildren([Header, Footer], children)

  return (
    <Wrapper>
      {header}
      <Main>{main}</Main>
      {footer}
    </Wrapper>
  )
}

Menu.Item = Item
Menu.Section = Section
Menu.Footer = Footer
Menu.Header = Header

export default Menu
