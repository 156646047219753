import { FC } from 'react'
import styled from 'styled-components'
import { sortChildren } from '../../../utils'
import { HorizontalFlexContainer } from '../../boxes/horizontal-flex-container'
import MessageContent from "./message-content"
import MessageTitle from "./message-title"
import MessageHead from "./message-head"

const Container = styled(HorizontalFlexContainer)`
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;

  &:hover ${MessageHead} {
    visibility: visible;
  }

  &:hover {
    background-color: #cccccc2b;
  }
`

interface MessageHeadProps {
  show?: boolean
}

type MesageLayoutType = FC<{}> & {
  Head: FC<MessageHeadProps>
  Title: FC
}

const Message: MesageLayoutType = ({ children }) => {
  const [head, title, ...content] = sortChildren([MessageHead, MessageTitle], children)

  return (
    <Container>
      {head}

      <MessageContent>
        {title}
        {content}
      </MessageContent>
    </Container>
  )
}

Message.Head = MessageHead
Message.Title = MessageTitle

export default Message
