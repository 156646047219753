import styled from 'styled-components'

interface FooterProps {
  highlight?: boolean
}

export const Footer = styled.div<FooterProps>`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-top: ${({ highlight = false }) => highlight ? "1px solid #ccc" : "none"};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
