import { FC } from 'react'
import styled from 'styled-components'

interface PanelProps {
  onClick?: () => void
}

const Container = styled.div<PanelProps>`
  padding: 1rem 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
  overflow: hidden;
  background-color: ${props => props.theme.background.inverted};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 3px 0 rgba(18, 31, 62, 0.08);
`

export const Panel: FC<PanelProps> = ({ children, onClick }) =>
  <Container onClick={onClick}>
    {children}
  </Container>
