import styled from 'styled-components'

interface ModuleProps {
  fullHeight?: boolean
}

export const Module = styled.div<ModuleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ fullHeight }) => fullHeight && "height: 100%;"}
`
