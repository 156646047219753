import { FC, useState } from "react"
import PulseLoader from "react-spinners/PulseLoader"
import Icon from "../icon"
import { Text } from "../typography"
import { Button } from "./button"

interface ActionButtonProps {
  icon: string
  type?: "button"|"reset"|"submit"
  title?: string
  onClick?: () => void
  onComplete?: () => void
  color?: "primary"|"secondary"|"danger"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  disabled?: boolean
}

const Spinner = () =>
  <PulseLoader size="0.25rem" color="white" css="height: 10px" />

export const ActionButton: FC<ActionButtonProps> = ({ icon, onClick, onComplete = () => {}, type = "button", size, title = "", disabled = false, color = "primary" }) => {
  const [isWaiting, setWaiting] = useState(false)

  const handleClick = async () => {
    if (!onClick) {
      return
    }

    setWaiting(true)
    await onClick()
    setWaiting(false)

    onComplete()
  }

  const IconComponent = isWaiting ? Spinner : Icon[icon]

  return (
    <Button
      type={type}
      onClick={!isWaiting ? handleClick : undefined}
      disabled={disabled}
      color={disabled ? "disabled" : color}
      size={size}
    >
      <IconComponent size="16px" />&nbsp;
      {title && <Text color="inverted">{title}</Text>}
    </Button>
  )
}
