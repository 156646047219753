import { FC } from 'react'
import styled from "styled-components"
import { Title, Text } from "../typography"
import { Button } from "../buttons"
import { Spinner } from "./spinner"

interface AsyncContentProps {
  loading: boolean
  failed: boolean
  size?: "xsm" | "sm" | "m" | "lg" | "xlg"
  retry?: () => void
}

interface MessageContainerProps {
  width?: string
}

const MessageContainer = styled.div<MessageContainerProps>`
  width: ${({ width = '200px' }) => width};
  margin: auto;
  padding-top: 30vh;
  display: flex;
  justify-content: flex-start;
`

const MessageSpace = styled.div`
  margin: auto;
`

export const AsyncContent: FC<AsyncContentProps> = ({ children, loading, failed, retry, size = "m" }) => {
  if (failed) {
    return (
      <MessageContainer width="400px">
        <Title color="danger">
          We did not anticipate this issue.
          Click to return to the login page and
          contact our Support team <a href="https://support.navarik.com/support/home">(https://support.navarik.com/support/home)</a>.
        </Title>
        {retry && (
          <Button onClick={retry} size="sm">Click to return</Button>
        )}
      </MessageContainer>
    )
  }

  if (loading) {
    return (
      <MessageContainer>
        <Spinner size={size} />
        <MessageSpace>
          <Text size={size}>Loading...</Text>
        </MessageSpace>
      </MessageContainer>
    )
  }

  return (
    <>{children}</>
  )
}
