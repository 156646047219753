import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { Form, SpacedBox, ActionButton, Section, Title } from "../../components"
import { useCore } from '../../core'
import { Tenant } from './types'

interface TenantFormProps {
  id?: string
  version_id?: string
  body?: Tenant
  onSuccess: (document: CoreDocument<Tenant>) => any
  onCancel: () => any
}

const TenantForm: FC<TenantFormProps> = ({ id, version_id, onSuccess, onCancel, body = {} }) => {
  const { core } = useCore()

  const saveTenantProfile = async (body) =>  {
    const { result } = await (id
      ? core.command("navarik.id.tenant.profile.update", { id, versionId: version_id, name: body.name })
      : core.command("navarik.id.tenant.profile.create", { name: body.name }))

    await core.refreshSession()

    onSuccess(result as CoreDocument<Tenant>)
  }

  return (
    <Form onSubmit={saveTenantProfile} data={{ ...body }}>
      <Section>
        <Section.Header>
          <Title>{id ? "Edit Organization Profile" : "Create Organization"}</Title>
        </Section.Header>

        <Form.Field id="name">
          <Form.Label>Name</Form.Label>
          <Form.Input autoFocus type="text" field="name" />
          <Form.Validators.Required field="name">This field cannot be blank</Form.Validators.Required>
        </Form.Field>

        <Section.Footer>
          <SpacedBox align="spread">
            <ActionButton type="submit" title="Save" icon="Save" />
            <ActionButton color="secondary" title="Cancel" icon="X" onClick={onCancel} />
          </SpacedBox>
        </Section.Footer>
      </Section>
    </Form>
  )
}

export default TenantForm
