import { FC } from 'react'
import { SpacedBox, Text, Field, Section, Button } from "../../../components"

interface GeneratedSecretProps {
  secret: string
  onClick: () => void
}

const GeneratedSecret: FC<GeneratedSecretProps> = ({ secret, onClick }) =>
  <Section>
    <SpacedBox direction="column">
      <Field title="Client Secret:">{secret}</Field>
      <Text color="danger">Important: client secrets are shown only once when they are generated. This value is not stored in the database and won't be shown again. Please, save this value in a secure location.</Text>
    </SpacedBox>

    <Section.Footer>
      <Button onClick={onClick}>OK</Button>
    </Section.Footer>
  </Section>

export default GeneratedSecret
