import { FC } from 'react'
import { Spinner, useObservable, Title, useTray, SpacedBox, ActionButton, Module, Toolbar, FieldCluster, Field } from "../../components"
import { useCore } from '../../core'
import BridgeOrganizationList from './bridge-organization/bridge-organization-list'
import { Tenant } from './types'
import TenantForm from './tenant-form'

interface TenantViewProps {
  id: string
}

const TenantView: FC<TenantViewProps> = ({ id }) => {
  const { open: openTray, close: closeTray } = useTray()
  const { core } = useCore()

  const tenant = useObservable(core.getDocument<Tenant>(id))

  const editTenantProfile = () => openTray(
    <TenantForm {...tenant} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  if (!tenant) {
    return <Spinner />
  }

  return (
    <SpacedBox direction="column">
      <Module>
        <Toolbar>
          <SpacedBox align="spread">
            <Title>Organization Profile</Title>
            <ActionButton onClick={editTenantProfile} icon="Edit" title="Edit" />
          </SpacedBox>
        </Toolbar>

        <FieldCluster>
          <Field title="Name:">{tenant.body.name}</Field>
        </FieldCluster>
      </Module>

      <BridgeOrganizationList tenantId={id} />
    </SpacedBox>
  )
}

export default TenantView
