import styled, { ThemeProvider } from 'styled-components'
import theme from "./theme"

export const Wrapper = styled.div`
  height: 100vh;
  font-family: ${({ theme }) => theme.fonts};
`

export const ApplicationContainer = ({ children }) =>
  <ThemeProvider theme={theme}>
    <Wrapper>
      {children}
    </Wrapper>
  </ThemeProvider>

