import { FC } from 'react'
import { List, useTray, useObservableCollection, ActionButton, SpacedBox, Text, Toolbar, Module } from "../../components"
import { Workspace } from './types'
import WorkspacesCard from './workspace-card'
import WorkspaceForm from './workspace-form'
import { useCore } from '../../core'

interface WorkspacesListProps {
  onView: (id: string) => void
}

const WorkspacesList: FC<WorkspacesListProps> = ({ onView }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const workspaces = useObservableCollection(core.query<Workspace>({
    type: "navarik.bridge.workspace"
  }, { limit: 1000, sort: "created_at:desc" }))

  const createWorkspace = () => openTray(
    <WorkspaceForm onSuccess={closeTray} onCancel={closeTray} />,
    { size: "large" }
  )

  return (
    <Module fullHeight>
      <Toolbar>
        <SpacedBox align="spread">
          <Text size="lg">Workspaces</Text>
          <ActionButton onClick={createWorkspace} icon="Plus" title="Create New" />
        </SpacedBox>
      </Toolbar>

      <List>
      {workspaces.map((item) =>
        <WorkspacesCard
          id={item.id}
          description={item.body.description}
          key={`service_accounts_list_item_${item.id}`}
          onClick={() => onView(item.id)}
        />
      )}
      </List>
    </Module>
  )
}

export default WorkspacesList
