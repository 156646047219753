import { FC } from 'react'
import styled from 'styled-components'

interface BoxProps {
  onClick?: () => void
}

const Container = styled.div<BoxProps>`
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
  overflow: hidden;
`

export const PaddedBox: FC<BoxProps> = ({ children, onClick }) =>
  <Container onClick={onClick}>
    {children}
  </Container>
