import { CoreClient } from "@navarik/core-client-sdk"
import { FC, createContext, useContext, useState } from 'react'
import { AsyncContent, useEffectOnce } from "../components"
import { AuthClientAdapter } from "./types"

interface CoreContextInterface {
  core: CoreClient
  isAuthenticated: boolean
  isInitializing: boolean
  login: () => Promise<void>
  switchPersona: (id: string) => Promise<void>
  logout: () => void
}

const CoreContext = createContext<any>({})

interface CoreProviderProps {
  baseUrl: string
  authClient: AuthClientAdapter
}

let core
const getCoreInstance = (baseUrl, identityProvider) => {
  if (!core) {
    core = new CoreClient({ baseUrl, identityProvider })
  }

  return core
}

export const CoreProvider: FC<CoreProviderProps> = ({ children, baseUrl, authClient }) => {
  const core = getCoreInstance(baseUrl, authClient)
  const [state, setState] = useState({ isAuthenticated: false, isInitializing: true })
  const [isCoreConnected, setIsCoreConnected] = useState(true)

  const login = async () => {
    await authClient.init()

    if (!authClient.isAuthenticated) {
      await authClient.login()
      return
    }
    await core.connect().catch(() => setIsCoreConnected(false))

    setState({
      isAuthenticated: authClient.isAuthenticated,
      isInitializing: false
    })
  }

  const switchPersona = async (id: string) => {
    setState({
      isAuthenticated: authClient.isAuthenticated,
      isInitializing: true
    })

    await core.switchPersona(id).catch(() => setIsCoreConnected(false))

    setState({
      isAuthenticated: authClient.isAuthenticated,
      isInitializing: false
    })
  }

  const logout = async () => {
    setState({
      isAuthenticated: authClient.isAuthenticated,
      isInitializing: true
    })
    await core.disconnect()
    await authClient.logout()
  }

  useEffectOnce(login)

  const contextValue: CoreContextInterface = {
    core,
    isAuthenticated: state.isAuthenticated,
    isInitializing: state.isInitializing,
    login,
    switchPersona,
    logout
  }

  return (
    <CoreContext.Provider value={contextValue}>
      <AsyncContent loading={state.isInitializing} failed={!isCoreConnected} retry={async () => await logout()} size="xlg">
        {!state.isInitializing && children}
      </AsyncContent>
    </CoreContext.Provider>
  )
}

export const useCore = () => useContext<CoreContextInterface>(CoreContext)
