export const alphabeticallyBy = (property: string) => (a, b) => {
  if (a === null || typeof a !== "object" || !a[property]) {
    return -1
  }

  if (b === null || typeof b !== "object" || !b[property]) {
    return 1
  }

  const valueA = a[property].toLowerCase()
  const valueB = b[property].toLowerCase()

  return valueA === valueB ? 0 : (valueA < valueB ? -1 : 1)
}
