import { FC, useEffect, useState } from 'react'
import isEmpty from "is-empty"
import { useForm } from "../form-context"
import Feedback from "../feedback"

interface RequiredValidatorProps {
  field: string
}

const RequiredValidator: FC<RequiredValidatorProps> = ({ field, children }) => {
  const [valid, setValid] = useState(true)
  const { registerValidator, getField } = useForm()

  useEffect(() => {
    registerValidator(() => {
      const isValid = !isEmpty(getField(field))
      setValid(isValid)

      return isValid
    })
  }, [registerValidator, field, getField])

  return (
    <>
      {!valid && <Feedback type="error">{children}</Feedback>}
    </>
  )
}

export default RequiredValidator
