import escapeStringRegexp from 'escape-string-regexp'
import { Dictionary } from '@navarik/types'
import { FC, useState } from 'react'
import { Form, useObservableCollection } from "../../components"
import { useCore } from '../../core'
import { alphabeticallyBy } from '../../utils'

interface ReferenceSelectorProps {
  field: string
  entityType: string
  filter?: Dictionary<any>
  nameField?: string
  limit?: number
}

const ReferenceSelector: FC<ReferenceSelectorProps> = ({ field, entityType, filter = {}, nameField = "name", limit = 5 }) => {
  const { core } = useCore()
  const [searchString, setSearchString] = useState("")

  const allItems = useObservableCollection<any>(core.query({ ...filter, type: entityType }, {}))

  const pattern = new RegExp(escapeStringRegexp(searchString), "i")
  const filteredItems = allItems
    .filter(x => x.body[nameField].match(pattern))
    .sort(alphabeticallyBy(nameField))
    .slice(0, limit)

  const options = filteredItems.reduce((acc, next) => ({ ...acc, [next.id]: next.body[nameField] }), {})

  return (
    <Form.Select options={options} field={field} onInput={setSearchString} />
  )
}

export default ReferenceSelector
