import { FC } from 'react'
import { List, useTray, useObservableCollection, ActionButton, SpacedBox, Text, Toolbar, Module, ActionableContainer, Icon } from "../../../components"
import { useCore } from '../../../core'
import { BridgeOrganization } from '../types'
import BridgeOrganizationCard from './bridge-organization-card'
import BridgeOrganizationForm from './bridge-organization-form'

interface BridgeOrganizationListProps {
  tenantId: string
}

const BridgeOrganizationList: FC<BridgeOrganizationListProps> = ({ tenantId }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const bridgeOrganizations = useObservableCollection(core.query<BridgeOrganization>({
    type: "navarik.bridge.organization",
    "body.audience": tenantId
  }, { limit: 1000, sort: "created_at:desc" }))

  const createRegistryRecord = () => openTray(
    <BridgeOrganizationForm onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  const editRegistryRecord = (id: string, body: BridgeOrganization) => openTray(
    <BridgeOrganizationForm id={id} body={body} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  return (
    <Module fullHeight>
      <Toolbar>
        <SpacedBox align="spread">
          <Text size="lg">Bridge Registry</Text>
          <ActionButton onClick={createRegistryRecord} icon="Plus" title="Register" />
        </SpacedBox>
      </Toolbar>

      <List>
      {bridgeOrganizations.map((item) =>
        <ActionableContainer key={`bridge_org_list_item_${item.id}`}>
          <BridgeOrganizationCard
            id={item.id}
            name={item.body.name}
          />

          <ActionableContainer.Actions>
            <ActionableContainer.Action>
              <Icon.Edit onClick={() => editRegistryRecord(item.id, item.body)} />
            </ActionableContainer.Action>
          </ActionableContainer.Actions>
        </ActionableContainer>
      )}
      </List>
    </Module>
  )
}

export default BridgeOrganizationList
