import { FC } from 'react'
import ReactSelect from "react-select"

interface SelectProps {
  onChange: (value: any) => void
  onInput: (value: string) => void
  options: Array<{ value: string, label: string }>
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlight"|"inverted"|"danger"
  placeholder?: string
  autoFocus?: boolean
  value?: any
}

export const Select: FC<SelectProps> = ({ onChange, onInput, options, placeholder = "", value = "", autoFocus = true }) => {
  return (
  <ReactSelect
    // size={size}
    // color={color}
    options={options}
    autoFocus={autoFocus}
    value={options.find(x => x.value === value)}
    placeholder={placeholder}
    onChange={onChange}
    onInputChange={onInput}
  />
  )}