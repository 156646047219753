import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Menu, Title } from "../../components"
import AppSelector from "./app-selector"

type UiState = "full"|"short"

interface Item {
  icon: string
  title: string
  route: string
}

interface MainMenuProps {
  state: UiState
  appName: string
  flipState: () => void
  navigateTo: (path: string) => void
  items: Array<Item>
}

export const MainMenu: FC<MainMenuProps> = ({ navigateTo, state, flipState, items, appName }) => {
  const location = useLocation()
  const currentRoute = `/${location.pathname.split("/")[1]}`

  return (
    <Menu>
      <Menu.Header>
        <AppSelector navigateTo={navigateTo} />
        {state === "full" &&
          <Title color="inverted">{appName}</Title>
        }
      </Menu.Header>

      {items.map(item =>
        <Menu.Item
          key={`menu_item_${item.route}`}
          short={state === "short"}
          icon={item.icon}
          title={item.title}
          onClick={() => navigateTo(item.route)}
          current={currentRoute === item.route}
        />
      )}

      <Menu.Footer>
        <Menu.Item
          short={state === "short"}
          icon={state === "short" ? "ChevronRight" : "ChevronLeft"}
          title="Collapse"
          onClick={flipState}
          current={false}
        />
      </Menu.Footer>
    </Menu>
  )
}
