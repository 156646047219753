import { FC, useEffect, useState } from 'react'
import { useForm } from "../form-context"
import Feedback from "../feedback"

interface ConstraintValidatorProps {
  fields: Array<string>
  constraint: (...value: Array<any>) => boolean|Promise<boolean>
}

const ConstraintValidator: FC<ConstraintValidatorProps> = ({ fields, constraint, children }) => {
  const [valid, setValid] = useState(true)
  const { registerValidator, getField } = useForm() 
  useEffect(() => {
    
    registerValidator(async () => {
      const isValid = await constraint(...fields.map(getField))
      setValid(isValid)

      return isValid
    })
    return ()=> {}
  }, [registerValidator, fields, getField])

  return (
    <>
      {!valid && <Feedback type="error">{children}</Feedback>}
    </>
  )
}

export default ConstraintValidator
