import styled from 'styled-components'

interface MessageHeadProps {
  show?: boolean
}

const MessageHead = styled.div<MessageHeadProps>`
  width: 50px;
  height: 100%;
  flex-shrink: 0;
  align-self: flex-start;
  justify-self: flex-start;
  visibility: ${props => props.show ? "visible" : "hidden"};
`

export default MessageHead
