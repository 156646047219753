import { FC } from "react"
import styled from "styled-components"
import { SpacedBox, Icon } from "./index"

interface ToastProps {
  icon: string
  onClick?: () => void
}

const Wrapper = styled.div`
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border["main"]};
  cursor: ${props => props.onClick ? "pointer" : "default"};
`

const IconSpace = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.background.light};
`

export const Toast: FC<ToastProps> = ({ children, icon, onClick }) => {
  const IconComponent = Icon[icon]

  return (
    <Wrapper onClick={onClick}>
      <SpacedBox>
        <IconSpace>
          <IconComponent size={30} color="#004A97" />
        </IconSpace>

        {children}
      </SpacedBox>
    </Wrapper>
  )
}
