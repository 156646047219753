import { CoreDocument } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { Form, SpacedBox, ActionButton, Section, Title } from "../../../components"
import { useCore } from '../../../core'
import { BridgeOrganization } from '../types'

interface BridgeOrganizationFormProps {
  id?: string
  body?: BridgeOrganization
  onSuccess: (document: CoreDocument<BridgeOrganization>) => any
  onCancel: () => any
}

const BridgeOrganizationForm: FC<BridgeOrganizationFormProps> = ({ id, onSuccess, onCancel, body = {} }) => {
  const { core } = useCore()

  const save = async (body) => {
    if (!body.name) {
      return
    }
    const { result } = await (id
      ? core.command("navarik.bridge.organization.update", { id, ...body })
      : core.command("navarik.bridge.organization.register", body))

    onSuccess(result as CoreDocument<BridgeOrganization>)
  }

  const isUnique = async (name) => {
    if (!name) {
      return true
    }

    const { result } = await core.command("navarik.bridge.organization.searchByName", { name })
    if (result.find(x => x.body.name.toLowerCase() === name.toLowerCase().trim())) {
      return false
    }
    return true
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Section>
        <Section.Header>
          <Title>{id ? "Edit Bridge Organization Record" : "Register in Bridge"}</Title>
        </Section.Header>

        <Form.Field id="name">
          <Form.Label>Name</Form.Label>
          <Form.Input autoFocus type="text" field="name" />
          <Form.Validators.Required field="name">This field cannot be blank</Form.Validators.Required>
          <Form.Validators.Constraint fields={["name"]} constraint={isUnique}>Organization already exists</Form.Validators.Constraint>
        </Form.Field>

        <Section.Footer>
          <SpacedBox align="spread">
            <ActionButton type="submit" title="Save" icon="Save" />
            <ActionButton color="secondary" title="Cancel" icon="X" onClick={onCancel} />
          </SpacedBox>
        </Section.Footer>
      </Section>
    </Form>
  )
}

export default BridgeOrganizationForm
