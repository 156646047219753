import { Children, ReactElement } from 'react'

export const sortChildren = (classes: Array<any>, children) => {
  const sorted = new Map()
  const rest: Array<any> = []

  Children.forEach(children, child => {
    const element = child as ReactElement<any>
    if (element && classes.includes(element.type)) {
      sorted.set(element.type, element)
    } else {
      rest.push(element)
    }
  })

  return classes.map(x => sorted.get(x)).concat(rest)
}
