import { FC } from 'react'
import { Card, SpacedBox, Text, Field } from "../../components"

interface ServiceAccountCardProps {
  clientId: string
  description?: string
  onClick?: () => void
}

const ServiceAccountCard: FC<ServiceAccountCardProps> = ({ clientId, description, onClick }) =>
  <Card onClick={onClick}>
    <SpacedBox align="spread">
      <Text>{description}</Text>
      <Field title="Client ID:">{clientId}</Field>
    </SpacedBox>
  </Card>

export default ServiceAccountCard
