import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  width: 100%;
  padding: 1rem 2rem 0 2rem;
  overflow: hidden;
`

export default Header
