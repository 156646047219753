import { FC } from "react"
import styled from 'styled-components'
import { Text } from "../typography"

const LabelContainer = styled.span`
  padding: 0 0.5rem 0 0;
  flex-basis: 20%;
  flex-shrink: 0;
`

const Label: FC = ({ children }) =>
  <LabelContainer>
    <Text color="light">{children}</Text>
  </LabelContainer>

export default Label
