import { FC } from "react"
import styled from 'styled-components'
import { DropEvent, FileRejection, useDropzone } from "react-dropzone"
import Icon from "../icon"

const Container = styled.div`
  padding: 20px 10px 10px 10px;
  outline: none;
  text-align: center;
  color: #8f8f8f;
`

const FileUploadDropZone = styled.div`
  padding: 1.5em;
  transition: box-shadow 0.3s;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  justify-items: center;
  align-items: center;
  outline: none;

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {taaskmgr
    background-color: #cccccc2b;
    cursor: pointer;
  }
`

interface DropzoneProps {
  onDrop: (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, event: DropEvent) => void
  maxSize?: number // In MB
}

const Dropzone: FC<DropzoneProps> = ({ onDrop, maxSize = 1}) => {
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: maxSize * 1024 * 1024
  })

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <FileUploadDropZone>
        <Icon.UploadCloud size={26} />
        {isDragActive
          ? <div>Drop the file to upload.</div>
          : <div>Drop a file to upload or Browse.</div>
        }
      </FileUploadDropZone>
    </Container>
  )
}

export default Dropzone
