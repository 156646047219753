import { FC } from 'react'
import { List, useTray, useObservableCollection, ActionButton, SpacedBox, Text, Toolbar, Module } from "../../components"
import { ServiceAccount } from './types'
import ServiceAccountsCard from './service-account-card'
import ServiceAccountForm from './service-account-form'
import { useCore } from '../../core'

interface ServiceAccountsListProps {
  tenantId: string
  onView: (id: string) => void
}

const ServiceAccountsList: FC<ServiceAccountsListProps> = ({ tenantId, onView }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const serviceAccounts = useObservableCollection(core.query<ServiceAccount>({
    type: "navarik.id.serviceAccount",
    "body.tenant": tenantId
  }, { limit: 1000, sort: "created_at:desc" }))

  const createServiceAccount = () => openTray(
    <ServiceAccountForm body={{ tenant: tenantId }} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  return (
    <Module fullHeight>
      <Toolbar>
        <SpacedBox align="spread">
          <Text size="lg">Service Accounts</Text>
          <ActionButton onClick={createServiceAccount} icon="Plus" title="Create New" />
        </SpacedBox>
      </Toolbar>

      <List>
      {serviceAccounts.map((item) =>
        <ServiceAccountsCard
          {...item.body}
          key={`service_accounts_list_item_${item.id}`}
          onClick={() => onView(item.id)}
        />
      )}
      </List>
    </Module>
  )
}

export default ServiceAccountsList
