import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
`

const ChatScrollingContainer: FC<{}> = ({ children }) => {
  let messagesEndRef: any = useRef(null)
    useEffect(() => {
      messagesEndRef.current.scrollIntoView(false)
    }
  )

  return (
    <Container>
      {children}
      <div ref={messagesEndRef} />
    </Container>
  )
}

export default ChatScrollingContainer
