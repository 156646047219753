import styled from 'styled-components'

const Footer = styled.div`
  width: 100%;
  min-height: min-content;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0.75rem 2rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.background.light};
  border-top: ${({ theme }) => theme.border["main"]};
`

export default Footer
