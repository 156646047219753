const theme = {
  background: {
    main: "#004A97",
    dark: "#001B38",
    light: "#F7F9FC",
    inverted: "#FFFFFF"
  },
  textColor: {
    main: "#212529",
    light: "#727272",
    highlight: "#004A97",
    inverted: "#FFFFFF",
    danger: "#944A00"
  },
  positions: {
    start: "flex-start",
    end: "flex-end",
    left: "flex-start",
    right: "flex-end",
    center: "center",
    spread: "space-between"
  },
  actionColor: {
    disabled: "#727272",
    primary: "#004A97",
    secondary: "#944A00",
    danger: "#94004A"
  },
  fonts: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSizes: {
    xsm: "0.75rem",
    sm: "0.875rem",
    m: "1rem",
    lg: "1.125rem",
    xlg: "1.5rem"
  },
  border: {
    main: "1px solid #ced4da"
  },
  borderRadius: "0.25rem"
};

export default theme