import ClipLoader from "react-spinners/ClipLoader"

const SIZES = {
  xsm: "10px",
  sm: "20px",
  m: "30px",
  lg: "40px",
  xlg: "50px"
}

export const Spinner = ({ size = "sm" }) =>
  <ClipLoader size={SIZES[size]} color="grey" />
