import { FC } from 'react'
import { Text, useObservable, Toolbar, Module, ActionButton, FieldCluster, Field, SpacedBox, useTray, Spinner } from "../../components"
import { useCore } from '../../core'
import { ReferenceName } from '../../shared'
import { Workspace } from './types'
import WorkspaceForm from './workspace-form'

interface WorkspaceViewProps {
  id: string
}

const WorkspaceView: FC<WorkspaceViewProps> = ({ id }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const workspace = useObservable(core.getDocument<Workspace>(id))
  if (!workspace) {
    return <Spinner />
  }

  const editWorkspace = () => openTray(
    <WorkspaceForm id={id} body={workspace.body} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "large" }
  )

  return (
    <SpacedBox direction="column">
      <Module>
        <Toolbar>
          <SpacedBox align="spread">
            <Text size="lg">Workspace "{workspace.body.description}"</Text>
            <ActionButton onClick={editWorkspace} icon="Edit" title="Edit" />
          </SpacedBox>
        </Toolbar>

        <FieldCluster>
          <Field title="Description:">{workspace.body.description}</Field>
          <Field title="Client:"><ReferenceName id={workspace.body.parties.client.id} /></Field>
          <Field title="Vendor:"><ReferenceName id={workspace.body.parties.vendor.id} /></Field>
        </FieldCluster>
      </Module>
    </SpacedBox>
  )
}

export default WorkspaceView
