import { FC } from 'react'
import { Text, List, Toolbar, Module, useObservableCollection, ActionButton, SpacedBox, useModal, ActionableContainer, Icon } from "../../../components"
import { useCore } from '../../../core'
import { ConfirmationModal } from '../../../shared'
import { newestFirst } from '../../../utils'
import { ClientSecret } from '../types'
import GeneratedSecret from './generated-secret'
import SecretCard from './secret-card'

interface ClientSecretListProps {
  tenantId: string
  id: string
}

const ClientSecretList: FC<ClientSecretListProps> = ({ tenantId, id }) => {
  const { core } = useCore()
  const { open: openModal, close: closeModal } = useModal()

  const clientSecrets = useObservableCollection(core.query<ClientSecret>({
    type: "navarik.id.clientSecret",
    "body.serviceAccount": id
  }, { limit: 1000, sort: "created_at:desc" }))

  const generateSecret = async () => {
    const { result: secretDocument } = await core.command("navarik.id.clientSecret.create", {
      tenant: tenantId,
      serviceAccount: id
    })
    const secret = secretDocument.body.secret

    await core.command("navarik.id.clientSecret.update", {
      tenant: tenantId,
      serviceAccount: id,
      id: secretDocument.id,
      description: `....${secret.substr(-4)}`
    })

    openModal(
      <GeneratedSecret secret={secret} onClick={closeModal} />,
      { title: "Client secret created successfully." }
    )
  }

  const deleteSecret = (secretId, description) => openModal(
    <ConfirmationModal
      text={`Are you sure you want to delete client secret ${secretId} (${description})?`}
      onConfirm={() => core.command("navarik.id.clientSecret.delete", {
        tenant: tenantId,
        serviceAccount: id,
        id: secretId
      })}
      onCancel={closeModal}
      onDone={closeModal}
    />,
    { title: "Confirm action." }
  )

  return (
    <Module fullHeight>
      <Toolbar>
        <SpacedBox align="spread">
          <Text size="lg">Client Secrets</Text>
          <ActionButton onClick={generateSecret} icon="Plus" title="Generate Secret" />
        </SpacedBox>
      </Toolbar>

      <List>
      {clientSecrets.sort(newestFirst).map((item) =>
        <ActionableContainer key={`client_secrets_list_item_${item.id}`}>
          <SecretCard {...item} />

          <ActionableContainer.Actions>
            <ActionableContainer.Action>
              <Icon.X onClick={() => deleteSecret(item.id, item.body.description)} />
            </ActionableContainer.Action>
          </ActionableContainer.Actions>
        </ActionableContainer>
      )}
      </List>
    </Module>
  )
}

export default ClientSecretList
