import { CurrentUser } from '@navarik/core-client-sdk'
import { FC } from 'react'
import { Form, ActionButton, SpacedBox, Section } from "../../components"
import { useCore } from '../../core'

interface MyProfileFormProps {
  body: CurrentUser
  onSuccess: () => any
  onCancel: () => any
}

const MyProfileForm: FC<MyProfileFormProps> = ({ body, onSuccess, onCancel }) => {
  const { core } = useCore()

  const save = async (data) => {
    await core.command("navarik.id.user.account.update", data)
    await core.refreshSession()
    onSuccess()
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Section>
        <Form.Field id="firstName" >
          <Form.Label>First Name</Form.Label>
          <Form.Input autoFocus type="text" field="firstName" />
          <Form.Validators.Required field="firstName">This field cannot be blank</Form.Validators.Required>
        </Form.Field>

        <Form.Field id="lastName" >
          <Form.Label>Last Name</Form.Label>
          <Form.Input type="text" field="lastName" />
          <Form.Validators.Required field="lastName">This field cannot be blank</Form.Validators.Required>
        </Form.Field>

        <Form.Field id="displayName" >
          <Form.Label>Display Name</Form.Label>
          <Form.Input type="text" field="displayName" />
          <Form.Validators.Required field="displayName">This field cannot be blank</Form.Validators.Required>
        </Form.Field>

        <Section.Footer>
          <SpacedBox align="spread">
            <ActionButton type="submit" title="Save Changes" icon="Save" />
            <ActionButton color="secondary" title="Cancel" icon="X" onClick={onCancel} />
          </SpacedBox>
        </Section.Footer>
      </Section>
    </Form>
  )
}

export default MyProfileForm
