import { FC } from "react"
import styled from 'styled-components'
import { Module } from "../boxes/module"
import { HorizontalFlexContainer } from "../boxes/horizontal-flex-container"
import Icon from "../icon"
import FileCard from "./file-card"
import UploadFeedback from "./upload-feedback"

const Container = styled(Module)`
  padding: 5px;
  margin-bottom: 5px;
`

const CloseIconButton = styled.div`
  height: 100%;
  cursor: pointer;
`

interface UploadListElementProps {
  name: string
  format: string
  size: number
  onDeselect: () => void
  status: string
  feedback?: string
}

const UploadListElement: FC<UploadListElementProps> = ({ name, format, size, onDeselect, status, feedback }) =>
  <Container>
    <HorizontalFlexContainer>
      <FileCard name={name} format={format} size={size} />

      <CloseIconButton onClick={onDeselect}>
        <Icon.XCircle color="#8f8f8f" size={26} />
      </CloseIconButton>
    </HorizontalFlexContainer>

    <UploadFeedback status={status} message={feedback} />
  </Container>

export default UploadListElement
