import { FC } from 'react'
import { Form, SpacedBox, Section, Title, ActionButton } from "../../components"
import { useCore } from '../../core'

interface ServiceAccountFormProps {
  id?: string
  body: {
    tenant: string
    description?: string
  }
  onSuccess: () => any
  onCancel: () => any
}

const ServiceAccountForm: FC<ServiceAccountFormProps> = ({ id, onSuccess, onCancel, body }) => {
  const { core } = useCore()

  const save = async ({ description }) =>  {
    const tenant = body.tenant

    await id
      ? core.command("navarik.id.serviceAccount.update", { tenant, id, description })
      : core.command("navarik.id.serviceAccount.create", { tenant, description })

    onSuccess()
  }

  return (
    <Form onSubmit={save} data={{ ...body }}>
      <Section>
        <Section.Header>
          <Title>{id ? "Edit Service Account" : "New Service Account"}</Title>
        </Section.Header>

        <Form.Field id="description">
          <Form.Label>Description</Form.Label>
          <Form.Input autoFocus type="text" field="description" />
        </Form.Field>

        <Section.Footer>
          <SpacedBox align="spread">
            <ActionButton type="submit" title="Save" icon="Save" />
            <ActionButton color="secondary" title="Cancel" icon="X" onClick={onCancel} />
          </SpacedBox>
        </Section.Footer>
      </Section>
    </Form>
  )
}

export default ServiceAccountForm
