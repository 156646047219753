import styled from 'styled-components'

interface ButtonProps {
  type?: "button"|"reset"|"submit"
  color?: "primary"|"secondary"|"danger"|"disabled"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  disabled?: boolean
  onClick?: () => void
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  padding: 0.35rem 0.75rem;
  color: ${({ theme }) => theme.textColor.inverted};
  background-color: ${({ theme, color = "primary" }) => theme.actionColor[color]};
  border: 1px solid ${({ theme, color = "primary" }) => theme.actionColor[color]};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: ${({ disabled = false }) => disabled ? "arrow" : "pointer"};
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  ms-text-overflow: ellipsis;
`
