import { FC } from 'react'
import { StatusIndicator } from "../../../components"

interface MembershipStatusProps {
  status: string
}

const statusMap = {
  active: "green",
  disabled: "red"
}

const statusName = {
  active: "active",
  disabled: "disabled"
}

const MembershipStatus: FC<MembershipStatusProps> = ({ status }) => (
  <StatusIndicator status={statusMap[status]} text={statusName[status]} />
)

export default MembershipStatus
