import { FC } from 'react'
import { Panel, useObservable, Spinner, useTray, Text, LinkButton } from "../../components"
import { useCore } from '../../core'
import Tenant from '../tenant'

interface NewUserPageProps {
}

const NewUserPage: FC<NewUserPageProps> = () => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const session = useObservable(core.session)
  if (!session) {
    return <Spinner />
  }

  const switchToNewTenant = async ({ id }) => {
    const persona = core.session.currentValue().personas.find(x => x.tenant.id === id)
    persona && await core.switchPersona(persona.id)
    closeTray()
  }

  const startNewTenant = () => openTray(
    <Tenant.Form onSuccess={switchToNewTenant} onCancel={closeTray} />,
    { size: "small" },
    closeTray
  )

  return (
    <Panel>
      <Text size="m" position="center">You are currently not a member of an organization.</Text>
      <Text size="m" position="center">
        You can wait to be invited into one or <LinkButton size="m" onClick={startNewTenant}>create your own</LinkButton>.
      </Text>
    </Panel>
  )
}

export default NewUserPage
