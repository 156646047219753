import { FC } from 'react'
import { Input } from "../../controls"
import { useForm } from "../form-context"

interface InputProps {
  field: string
  type?: string
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlight"|"inverted"|"danger"
  placeholder?: string
  autoFocus?: boolean
  onInput?: (value: string) => void
}

const FormInput: FC<InputProps> = ({ field, onInput = () => {}, ...props }) => {
  const { setField, getField } = useForm()

  const onChange = (value) => {
    setField(field, value)
    onInput(value)
  }

  return (
    <Input
      {...props}
      value={getField(field)}
      onChange={onChange}
    />
  )
}

export default FormInput
