import { FC, Children, PropsWithChildren } from "react"
import styled from 'styled-components'

type Directions = "row"|"column"
type Alignment = "start"|"end"|"center"|"spread"

interface SpacedBoxProps {
  direction?: Directions
  align?: Alignment
  onClick?: () => void
}

const Wrapper = styled.div<{ align: Alignment, direction: Directions }>`
  width: 100%;
  display: flex;
  justify-content: ${({ theme, align }) => theme.positions[align]};
  flex-direction: ${({ direction }) => direction};
  overflow: hidden;
`

const RowFirstElement = styled.div`
  margin: 0 0.5rem 0 0;
  flex-shrink: 0;
`

const RowMiddleElement = styled.div`
  margin: 0 0.5rem;
`

const RowLastElement = styled.div`
  margin: 0 0 0 0.5rem;
`

const ColumnFirstElement = styled.div`
  margin-top: 0;
  margin-bottom: 0.5rem;
  flex-shrink: 0;
  overflow: hidden;
`

const ColumnMiddleElement = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
`

const ColumnLastElement = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0;
  overflow: hidden;
`

export const SpacedBox: FC<PropsWithChildren<SpacedBoxProps>> = ({ children, onClick, align = "start", direction = "row" }) => {
  let first, last, rest
  const all: Array<any> = Children.toArray(children);

  [first, ...rest] = all
  last = rest.pop()

  const [FirstElement, MiddleElement, LastElement] = direction === "row"
    ? [RowFirstElement, RowMiddleElement, RowLastElement]
    : [ColumnFirstElement, ColumnMiddleElement, ColumnLastElement]

  return (
    <Wrapper align={align} direction={direction} onClick={onClick}>
      <FirstElement>{first}</FirstElement>
      {rest.map((element, i) =>
        <MiddleElement key={`part_${i}`}>{element}</MiddleElement>
      )}
      <LastElement>{last}</LastElement>
    </Wrapper>
  )
}
