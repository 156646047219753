import { FC } from "react"
import { emojiIndex } from "emoji-mart"
import styled from 'styled-components'
import { SpacedBox } from "../../boxes"

import "emoji-mart/css/emoji-mart.css"
import Icon from "../../icon"

interface EmojiSuggestionListProps {
  word: string
  maxSuggestions?: number
  onPick: (text: string) => void
  onCancel: () => void
}

const EmojiChoice = styled.div`
  text-align: left;
  width: fit-content;
  font-size: 24px;
  padding: 4px;
  margin-bottom: 5px;

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    cursor: pointer;
  }
`

const EmojiSuggestionList: FC<EmojiSuggestionListProps> = ({ word, onPick, onCancel, maxSuggestions = 7 }) => {
  const suggestions = emojiIndex.search(word)
  if (!suggestions) {
    return null
  }

  return (
    <SpacedBox align="spread">
      {suggestions.slice(0, maxSuggestions).map((emoji: any) => (
        <EmojiChoice
          key={emoji.id}
          onClick={() => onPick(emoji)}
          title={emoji.colons}
        >
          {emoji.native}
        </EmojiChoice>
      ))}

      <Icon.XCircle onClick={onCancel} size="20px" />
    </SpacedBox>
  )
}

export default EmojiSuggestionList