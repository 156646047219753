import { useEffect, useRef } from 'react'

export function useEffectOnce(callback) {
  const called = useRef(false)

  useEffect(() => {
    if (called.current) {
      return
    }

    callback()

    called.current = true
  }, [callback])
}
