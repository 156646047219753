import { FC } from "react"
import styled from 'styled-components'
import { Icon } from "../../index"
import { useOverlay } from "../overlay-context"
import { Text } from "../../typography"

const sizes = {
  lg: "700px",
  large: "700px",
  sm: "350px",
  small: "350px",
}

type TraySize = "sm"|"small"|"lg"|"large"

interface PropsWithSize {
  size: TraySize
}

const Backdrop = styled.div<PropsWithSize>`
  background: #000;
  opacity: 0.5;
  width: calc(100% - ${(props) => sizes[props.size]});
  min-width: 10vw;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: 30;
  @media (max-width: 480px) {
    width: 10%;
  }
`

const Container = styled.div<PropsWithSize>`
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ size }) => sizes[size]};
  max-width: 90vw;
  overflow-y: auto;
  height: 100vh;
  top: 0;
  right: 0;
  padding: 0;
  background: white;
  z-index: 30;

  @media (max-width: 480px) {
    width: 90%;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 2rem;
  line-height: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
`

const Tray: FC<{}> = () => {
  const { isOpen, parameters, close, content } = useOverlay<{ size: TraySize }>("tray")

  if (!isOpen) {
    return null
  }

  const size = parameters.size || "small"

  return (
    <>
      <Backdrop size={size} onClick={close} />

      <Container size={size}>
        <CloseButton onClick={close}>
          <Text size="sm" color="light">close</Text>
          <Icon.ChevronRight color="#727272" size="18px" />
        </CloseButton>

        <Content>{content}</Content>
      </Container>
    </>
  )
}

export default Tray;
