import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
  background-color: transparent;
  border-bottom: ${({ theme }) => theme.border["main"]};
  width: 100%;
`

const Toolbar = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default Toolbar
