import { FC } from "react"
import { Picker } from "emoji-mart"
import styled from 'styled-components'
import { useKeydownHandler } from "../../hooks"

import "emoji-mart/css/emoji-mart.css"

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  right: 0px;
  bottom: 58px;
  z-index: 2;
`

interface EmojiPickerProps {
  onPick: (emoji: any) => void
  onClose: () => void
}

const EmojiPicker: FC<EmojiPickerProps> = ({ onPick, onClose }) => {
  useKeydownHandler((key) => {
    if (key === "Escape") {
      onClose()
    }
  })

  return (
    <Wrapper>
      <Picker onSelect={onPick} title="" autoFocus />
    </Wrapper>
  )
}

export default EmojiPicker