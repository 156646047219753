import { FC } from 'react'
import styled from 'styled-components'
import ShowMoreText from 'react-show-more-text'
import { Title } from "../index"

const Container = styled.div`
  padding-left: 1em;
  border-left: 4px solid #BBB;
`

const Body = styled.div`
  background-color: #cccccc2b;
`

type QuoteProps = {
  title?: any
}

const Quote: FC<QuoteProps> = ({ children, title = "" }) =>
  <Container>
    <Title size="xsm">{title}</Title>

    <Body>
      <ShowMoreText lines={2} more='Show more' less='Show less' expanded={false}>
        {children}
      </ShowMoreText>
    </Body>
  </Container>

export default Quote
