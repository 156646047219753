import { Dictionary } from '@navarik/types'
import { createContext, useContext, useState, FC } from 'react'
import { useKeydownHandler } from '..'
import { Overlay } from "./overlay"

interface OverlayContextInterface {
  overlays: Dictionary<Overlay>
  open: (name: string, overlay: Overlay) => void
  close: (name: string) => void
}

const OverlayContext = createContext<OverlayContextInterface>({
  overlays: {},
  open: () => {},
  close: () => {}
})

interface OverlayProviderProps {
  names: Array<string>
}

export const OverlayProvider: FC<OverlayProviderProps> = ({ children, names }) => {
  const overlays = names.reduce((acc, name) => ({ ...acc, [name]: new Overlay(null, {}) }), {})
  const [state, setState] = useState({ overlays, current: "" })

  useKeydownHandler((key) => {
    if (key === "Escape" && state.current) {
      close(state.current)
    }
  })

  const open = (name: string, overlay: Overlay) => {
    setState({
      overlays: {
        ...state.overlays,
        [name]: {
          ...overlay,
          isOpen: true
        }
      },
      current: name
    })
  }

  const close = (name: string) => {
    const current = state.overlays[name]

    setState({
      overlays: {
        ...state.overlays,
        [name]: {
          ...current,
          isOpen: false
        }
      },
      current: ""
    })

    current.onClose()
  }

  return (
    <OverlayContext.Provider value={{ overlays: state.overlays, open, close }}>
      <>{children}</>
    </OverlayContext.Provider>
  )
}

export function useOverlay<T extends object>(name: string) {
  const context = useContext(OverlayContext)
  if (!context) {
    throw new Error(`useOverlay must be used within a OverlayProvider.`)
  }

  const overlay = context.overlays[name] as Overlay<T>
  if (!overlay) {
    throw new Error(`Unknown overlay: ${name}.`)
  }

  return {
    ...overlay,
    open: (content, parameters?: T, onClose?: () => void) =>
      context.open(name, new Overlay<T>(content, parameters, onClose)),
    close: () => context.close(name)
  }
}
