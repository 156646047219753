import styled from 'styled-components'

interface SideMenuButtonProps {
  float?: boolean
  onClick?: () => void
}

const SideMenuButton = styled.button<SideMenuButtonProps>`
  flex: inherit;
  display: block;
  float: ${({ float }) => float ? 'right' : 'left'};
  border: none;
  background: content-box;

  :focus {
    outline: none;
  }
`

export default SideMenuButton
