import queryString from "query-string"
import { AuthClientAdapter } from "../types"

export class LocalAuthClient implements AuthClientAdapter {
  public isAuthenticated: boolean = false
  public isInitializing: boolean = false
  private idToken: string = ""

  async init() {}

  async getIdentityToken() {
    return this.idToken
  }

  async getUserProfile() {
    return {
      name: "Wow Doge"
    }
  }

  async login() {
    const params = queryString.parse(window.location.search)
    this.idToken = params.identity as string || "doge@doge.com"
    this.isAuthenticated = true
  }

  async logout() {
    this.isAuthenticated = false
    this.idToken = ""
    this.isAuthenticated = false
  }
}
