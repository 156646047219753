import styled from 'styled-components'

const Actions = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: none;
  width: auto;
`

export default Actions
