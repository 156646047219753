import styled from 'styled-components'
import { sortChildren } from '../../utils'
import Header from "./header"
import Footer from "./footer"
import Main from "./main"

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
`

const Section = ({ children }) => {
  const [header, footer, ...main] = sortChildren([Header, Footer], children)

  return (
    <Wrapper>
      {header && <>{header}</>}

      <Main>
        {main}
      </Main>

      {footer && <>{footer}</>}
    </Wrapper>
  )
}

Section.Header = Header
Section.Footer = Footer

export default Section
