import { FC } from 'react'
import { LinkButton, useObservable, Avatar, SpacedBox, Module, Text, useTray } from '../../components'
import { useCore } from '../../core'
import MyProfileForm from "./my-profile-form"

interface CurrentPersonaCardProps {
  onBack: () => void
}

const CurrentPersonaCard: FC<CurrentPersonaCardProps> = ({ onBack }) => {
  const { core } = useCore()
  const { open: openTray } = useTray()

  const session = useObservable(core.session)

  const editProfile = () => openTray(
    <MyProfileForm body={session.accountProfile} onSuccess={onBack} onCancel={onBack} />,
    { size: "small" },
    onBack
  )

  return (
    <SpacedBox>
      <Avatar size="lg" url={session.accountProfile.avatar} />

      <Module>
        <Text>{session.accountProfile.displayName}</Text>
        <Text color="light">{session.accountProfile.email}</Text>
        <Text>{session.tenant.name}</Text>
        <LinkButton onClick={editProfile}>edit profile</LinkButton>
      </Module>
    </SpacedBox>
  )
}

export default CurrentPersonaCard
