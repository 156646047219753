import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  background-color:  ${(props) => props.theme.background.light};
  border-bottom: ${(props) => props.theme.border["main"] };
  border-top-left-radius: ${props => props.theme.borderRadius};
  border-top-right-radius: ${props => props.theme.borderRadius};
`
