import styled from 'styled-components'

interface TabProps {
  active?: boolean
}

export const Tab = styled.div<TabProps>`
  min-width: 80px;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  color: ${props => props.active ? '#ffff' : '#d8d9da'};
  background-color: ${props => props.active ? props.theme.background.inverted : props.theme.background.light};
  cursor: pointer;
  line-height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.active ? "0 -2px 3px 0 rgba(18, 31, 62, 0.08)" : "none"};
  z-index: 3;
`
