const noop = () => {}

export class Overlay<T extends object = {}> {
  public isOpen: boolean = false
  public content: any
  public parameters: T
  public onClose: () => void

  constructor(content, parameters, onClose?) {
    this.content = content || null
    this.parameters = parameters || {}
    this.onClose = onClose || noop
  }
}
