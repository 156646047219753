import { FC } from "react"
import styled from 'styled-components'
import { HorizontalFlexContainer, Icon } from "../index"

interface MessageProps {
  success?: boolean
}

const Message = styled.div<MessageProps>`
  color: ${(props) => (props.success ? "green" : "red")};
  font-size: 14px;
  margin-left: 5px;
`

const IconSpace = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
`

interface UploadFeedbackProps {
  status: string
  message?: string
}

const UploadFeedback: FC<UploadFeedbackProps> = ({ status, message = "" }) =>
  <HorizontalFlexContainer>
    <IconSpace>
      {status === "processing" && (
        <Icon.ArrowRightCircle color="green" size={20} />
      )}

      {status === "uploaded" && (
        <Icon.CheckCircle color="green" size={20} />
      )}

      {status === "failed" && (
        <Icon.AlertCircle color="red" size={20} />
      )}
    </IconSpace>

    <Message success={status === "uploaded" || status === "processing"}>
      {message}
    </Message>
  </HorizontalFlexContainer>

export default UploadFeedback
