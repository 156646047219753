import { useCallback, useEffect } from 'react'

export function useKeydownHandler(handler: (key: string) => void) {
  const onKeyDown = useCallback((event: KeyboardEvent) => { handler(event.key) }, [handler])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  })
}
