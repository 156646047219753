import { FC } from 'react'
import moment from 'moment'

interface TimestampProps {
  format?: "calendar"|"time"|"date"
  children: string|Date
}

const CALENDAR_FORMAT_OPTIONS = {
  sameDay: '[Today at] LT', // Today at 3:05AM
  nextDay: '[Tomorrow at] LT', // Tomorrow at 3:05AM
  lastDay: '[Yesterday at] LT', // Yesterday at 3:05AM
  nextWeek: 'MMM Do [at] LT',  // May 21st at 3:05AM
  lastWeek: 'MMM Do [at] LT', // May 21st at 3:05AM
  sameElse: 'MMM Do[,] YYYY [at] LT' // May 21st, 2020 at 3:05AM
}

const Timestamp: FC<TimestampProps> = ({ children, format = 'calendar' }) => {
  if (!children) {
    return null
  }

  const date = moment(new Date(children))

  let formattedTimestamp = ""
  if (format === 'calendar') {
    formattedTimestamp = date.calendar(null, CALENDAR_FORMAT_OPTIONS)
  } else if (format === 'time') {
    formattedTimestamp = date.format('LT')
  } else if (format === 'date') {
    formattedTimestamp = date.format('MMM DD, YYYY')
  }

  return (
    <span>{formattedTimestamp}</span>
  )
}
export default Timestamp
