import styled from 'styled-components'

interface TextProps {
  position?: "left"|"center"|"right"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlight"|"inverted"|"danger"
  cropped?: boolean
}

const Text = styled.span<TextProps>`
  color: ${(({ theme, color = "main" }) => theme.textColor[color])};
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  align-self: ${({ theme, position = "left" }) => theme.positions[position]};
  margin-bottom: 0;
  word-break: break-word;
  overflow: ${({ cropped }) => cropped && 'hidden'};
  white-space: ${({ cropped }) => cropped && 'nowrap'};
  msTextOverflow: ${({ cropped }) => cropped && 'ellipsis'};
`

export default Text
