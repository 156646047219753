import { Children, ReactElement } from 'react'
import styled from 'styled-components'
import Left from "./left"
import Right from "./right"

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
`

const LeftSection = styled.div`
  width: 50%;
  height: 100%;
  overflow: auto;
`

const RightSection = styled.div`
  width: 50%;
  height: 100%;
  border-left: ${props => props.theme.border["main"]};
  overflow: auto;
`

const SplitScreen = ({ children }) => {
  let left, right

  Children.forEach(children, child => {
    const element = child as ReactElement<any>
    switch (element.type) {
      case Left:
        left = element
        break
      case Right:
        right = element
        break
      default:
        throw new Error(`[SplitScreen] Only <Left> or <Right> elements are allowed`)
    }
  })

  return (
    <Wrapper>
      <LeftSection>
        {left}
      </LeftSection>
      <RightSection>
        {right}
      </RightSection>
    </Wrapper>
  )
}

SplitScreen.Left = Left
SplitScreen.Right = Right

export default SplitScreen
