import { FC } from "react"
import { Avatar, useObservable } from "../../components"
import { useCore } from "../../core"
import { TenantMember, UserProfile } from "./types"

interface UserAvatarProps {
  id: string
  size: "xsm"|"sm"|"m"|"lg"|"xlg"
}

const UserAvatar: FC<UserAvatarProps> = ({ id, size }) => {
  const { core } = useCore()

  // Users referenced from other objects are membership records, user profile has to be de-references
  const tenantMember = useObservable(core.getDocument<TenantMember>(id))
  const user = useObservable(core.getDocument<UserProfile>(tenantMember.body.user))
  if (!user) {
    return <Avatar size={size} />
  }

  return <Avatar url={user.body.avatar} size={size} />
}

export default UserAvatar
