import { FC } from 'react'
import { Card, SpacedBox, Text, Field } from "../../components"

interface WorkspaceCardProps {
  id: string
  description?: string
  onClick?: () => void
}

const WorkspaceCard: FC<WorkspaceCardProps> = ({ id, description, onClick }) =>
  <Card onClick={onClick}>
    <SpacedBox align="spread">
      <Text>{description}</Text>
      <Field title="ID:">{id}</Field>
    </SpacedBox>
  </Card>

export default WorkspaceCard
