import styled from 'styled-components'

interface PillProps {
  active?: boolean
}

export const Pill = styled.div<PillProps>`
  min-width: 80px;
  min-height: 2rem;
  padding: 0.25rem 0.75rem;
  margin: 0 0.25rem;
  color: ${props => props.active ? '#ffff' : '#d8d9da'};
  border: ${props => props.theme.border["main"]};
  border-radius: 15px;
  background-color: ${props => props.active ? props.theme.background.light : props.theme.background.inverted};
  cursor: pointer;
  line-height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
