import { FC } from "react"
import styled from 'styled-components'

interface ActionSpaceProps {
  title?: any
}

const ActionSpaceContainer = styled.div`
  width: 100%;
  padding: 0.75rem;
  border-top: ${props => props.theme.border["main"]};
  background-color: ${props => props.theme.background.light};
`

const TitleSpace = styled.div`
  padding-bottom: 0.75rem;
`

const ActionSpace: FC<ActionSpaceProps> = ({ children, title }) =>
  <ActionSpaceContainer>
    <TitleSpace>
      {title}
    </TitleSpace>

    {children}
  </ActionSpaceContainer>

export default ActionSpace
