import { FC } from 'react'
import { Dictionary } from "@navarik/types"
import { Select } from "../../controls"
import { useForm } from "../form-context"

interface SelectProps {
  field?: string
  options: Dictionary<any>
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
  color?: "main"|"light"|"highlight"|"inverted"|"danger"
  placeholder?: string
  autoFocus?: boolean
  onChange?: (value: string) => void
  onInput?: (value: string) => void
}

const FormSelect: FC<SelectProps> = ({ field = "", onInput = () => {}, onChange = () => {}, options }) => {
  const { getField, setField } = useForm()

  const compiledOptions: Array<{ value: string, label: string }> = []
  for (const value in options) {
    compiledOptions.push({ value, label: options[value] })
  }

  return (
    <Select
      options={compiledOptions}
      onChange={({ value }) => {
        setField(field, value)
        onChange(value)
      }}
      value={getField(field)}
      onInput={onInput}
    />
  )
}

export default FormSelect
