import styled from 'styled-components'

interface FooterProps {
}

export const Footer = styled.div<FooterProps>`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ccc;
`
