import { FC, Children } from 'react'
import styled from 'styled-components'
import { SpacedBox } from './boxes'
import { Label, Text } from './typography'

const Block = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Line = styled.div``

interface FieldClusterProps {
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

export const FieldCluster: FC<FieldClusterProps> = ({ children, size = "sm" }) => {
  const titles: Array<any> = [], values: Array<any> = []
  Children.toArray(children).forEach((child) => {
    titles.push(child["props"]["title"])
    values.push(child["props"]["children"])
  })

  return (
    <SpacedBox align="start">
      <Block>
      {titles.map(title =>
        <Line key={`label_line_${title}`}>
          <Label size={size}>{title}</Label>
        </Line>
      )}
      </Block>

      <Block>
      {values.map((value, i) =>
        <Line key={`value_line_${i}`}>
          <Text size={size}>{value || "n/a"}</Text>
        </Line>
      )}
      </Block>
    </SpacedBox>
  )
}