import { FC, useState } from 'react'
import styled from 'styled-components'
import { HorizontalFlexContainer } from '../boxes'
import Icon from '../icon'

interface SectionProps {
  title: string
  onClick?: () => void
  current?: boolean
  collapsable?: boolean
}

const SectionContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 1rem 0 1rem 1rem;
`

interface SectionHeaderProps {
  clickable?: boolean
}

const SectionHeader = styled(HorizontalFlexContainer)<SectionHeaderProps>`
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
`

interface SectionTitleProps {
  current?: boolean
}

const SectionTitle = styled.div<SectionTitleProps>`
  color: ${({ theme }) => theme.textColor.inverted};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${props => props.current ? 'bold' : 'normal'};
  padding: 0 0 0.25rem 0.5rem;
`

const MenuSection: FC<SectionProps> = ({ children, title, onClick, current = false, collapsable = false }) => {
  const [open, setOpen] = useState(true)

  return (
    <SectionContainer>
      <SectionHeader clickable={!!onClick}>
        {collapsable &&
        (open
          ? <Icon.ChevronDown color="#FFFFFF" size="20" onClick={() => setOpen(false)} />
          : <Icon.ChevronRight color="#FFFFFF" size="20" onClick={() => setOpen(true)} />
        )}
        <SectionTitle onClick={onClick} current={current}>{title}</SectionTitle>
      </SectionHeader>

      {open && children}
    </SectionContainer>
  )
}

export default MenuSection
