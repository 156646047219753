import { FC } from 'react'
import { Text, useObservable, Toolbar, Module, ActionButton, FieldCluster, Field, SpacedBox, useTray, Spinner } from "../../components"
import { useCore } from '../../core'
import ClientSecretList from './secret/secret-list'
import ServiceAccountForm from './service-account-form'
import { ServiceAccount } from './types'

interface ServiceAccountViewProps {
  tenantId: string
  id: string
}

const ServiceAccountView: FC<ServiceAccountViewProps> = ({ tenantId, id }) => {
  const { core } = useCore()
  const { open: openTray, close: closeTray } = useTray()

  const serviceAccount = useObservable(core.getDocument<ServiceAccount>(id))
  if (!serviceAccount) {
    return <Spinner />
  }

  const editServiceAccount = () => openTray(
    <ServiceAccountForm id={id} body={serviceAccount.body} onSuccess={closeTray} onCancel={closeTray} />,
    { size: "small" }
  )

  return (
    <SpacedBox direction="column">
      <Module>
        <Toolbar>
          <SpacedBox align="spread">
            <Text size="lg">Service Account "{serviceAccount.body.description}"</Text>
            <ActionButton onClick={editServiceAccount} icon="Edit" title="Edit" />
          </SpacedBox>
        </Toolbar>

        <FieldCluster>
          <Field title="Description:">{serviceAccount.body.description}</Field>
          <Field title="User ID:">{serviceAccount.id}</Field>
          <Field title="OAuth2 Client ID:">{serviceAccount.body.clientId}</Field>
        </FieldCluster>
      </Module>

      <ClientSecretList tenantId={tenantId} id={id} />
    </SpacedBox>
  )
}

export default ServiceAccountView
