import { FC } from "react"
import styled from 'styled-components'
import FileIcon from "./file-icon"

const IMAGE_SIZES = {
  sm: "4em",
  m: "10em",
  lg: "100%"
}

interface ElementProps {
  size: "sm"|"m"|"lg"
}

const Container = styled.div<ElementProps>`
  display: flex;
  align-items: center;
  width: ${(props) => IMAGE_SIZES[props.size]};
  padding: 10px 0;

  &:hover {
    cursor: zoom-in;
  }
`

const Image = styled.img<ElementProps>`
  max-width: ${(props) => IMAGE_SIZES[props.size]};
  max-height: ${(props) => IMAGE_SIZES[props.size]};
`

type ThumbnailProps = {
  format: string
  onClick?: () => void
  src?: string
  size?: "sm"|"m"|"lg"
}

const Thumbnail: FC<ThumbnailProps> = ({ format, src = "", size = "sm", onClick = () => {} }) =>
  <Container onClick={onClick} size={size}>
  {format.includes("image") && src
    ? <Image src={src} alt={src} size={size} />
    : <FileIcon format={format} size={size} />
  }
  </Container>

export default Thumbnail
