import styled from 'styled-components'

interface FeedbackProps {
  type?: "error"|"warning"|"valid"
  size?: "xsm"|"sm"|"m"|"lg"|"xlg"
}

const typeColors = {
  error: "danger",
  warning: "danger",
  valid: "highlight"
}

const Feedback = styled.span<FeedbackProps>`
  color: ${(({ theme, type = "error" }) => theme.textColor[typeColors[type]])};
  font-size: ${({ theme, size = "sm" }) => theme.fontSizes[size]};
  word-break: break-all;
`

export default Feedback