import { FC, Children } from 'react'
import styled from 'styled-components'

const Container = styled.span``

const Item = styled.span``

interface CommaSeparatedListProps {
  delimiter?: string
}

export const CommaSeparatedList: FC<CommaSeparatedListProps> = ({ children, delimiter = ", " }) => {
  const childrenArray = Children.toArray(children)

  return (
    <Container>
      {childrenArray.map((child, index) =>
        <Item key={`list_item_${index}`}>
          {child}
          {index < (childrenArray.length - 1) && delimiter}
        </Item>
      )}
    </Container>
  )
}
