import { FC, Children } from "react"
import styled from 'styled-components'

const ListBody = styled.ul`
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }
`

const ListItem = styled.li`
  width: 100%;
  padding: 0.25rem 0;

  &:hover {
    background: ${({ theme }) => theme.background.light};
  }
`

const List: FC<{}> = ({ children }) =>
  <ListBody>
    {Children.toArray(children).map((element, i) =>
      <ListItem key={`list_item_${i}`}>{element}</ListItem>
    )}
  </ListBody>

export default List
