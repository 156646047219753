import { FC, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from "react-responsive"
import { sortChildren } from "../../utils"
import Icon from '../icon'
import { OverlayProvider, useOverlay } from './overlay-context'
import Header from './header'
import Sidebar from "./sidebar"
import Tray from './overlays/tray'
import SideMenuButton from './side-menu-button'
import Modal from './overlays/modal'

const Page = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0;
`

const SidebarSpace = styled.nav<{ collapsed?: boolean }>`
  height: 100%;
  width: ${({ collapsed = false }) => collapsed ? "70px" : "200px"};
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: ${props => props.theme.background.dark};
  border-right: 1px solid #292d32;
`

const ContentSpace = styled.div<{ expanded?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  width: ${({ expanded = false }) => expanded ? "calc(100% - 70px)" : "calc(100% - 200px)"};
  background-color: ${props => props.theme.background.light};
`

const TopNav = styled.div<{ short?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4rem;
  background-color: ${props => props.theme.background.main};
  z-index: 10;
`

const TopNavContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  background-color: ${props => props.theme.background.inverted};
  box-shadow 1px 2px 3px rgba(0, 0, 0, 0.1);
`

const MainArea = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  background-color: ${props => props.theme.background.inverted};
`

type AppLayoutType = FC<{ short?: boolean }> & {
  Header: typeof Header
  Sidebar: typeof Sidebar
  Tray: typeof Tray
}

const AppLayout: AppLayoutType = ({ children, short = false }) => {
  const [showSideBar, setShowSideBar] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const [header, sidebar, tray, ...main] = sortChildren([Header, Sidebar, Tray], children)

  return (
    <OverlayProvider names={["tray", "modal"]}>
      <Page>
        {(!isTabletOrMobile || showSideBar) &&
          <SidebarSpace collapsed={short}>
            {sidebar}
          </SidebarSpace>
        }

        <ContentSpace expanded={short}>
          <TopNav>
            {isTabletOrMobile &&
            <SideMenuButton onClick={() => setShowSideBar(!showSideBar)}>
              <Icon.AlignJustify color="white" size={30} />
            </SideMenuButton>
            }
            <TopNavContent>{header}</TopNavContent>
          </TopNav>

          <MainArea>
            {main}
          </MainArea>
        </ContentSpace>

        {tray}
      </Page>

      <Modal />
    </OverlayProvider>
  )
}

AppLayout.Header = Header
AppLayout.Sidebar = Sidebar
AppLayout.Tray = Tray

const useTray = () => useOverlay("tray")
const useModal = () => useOverlay("modal")

export { AppLayout, useTray, useModal }