import { FC, PropsWithChildren } from 'react'
import styled from "styled-components"

interface PaneProps {
  name: string
  title: string
}

const PaneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
  cursor: ${(props) => props.onClick ? "pointer" : "default"};
  background-color: ${props => props.theme.background.inverted};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-top-left-radius: 0;
  box-shadow: 0 0 3px 0 rgba(18, 31, 62, 0.08);
`

export const Pane: FC<PropsWithChildren<PaneProps>> = ({ children }) =>
  <PaneContainer>
    {children}
  </PaneContainer>
